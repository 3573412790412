import { useState, useEffect } from 'react';
import Papa from 'papaparse';

const useCsvParser = (csvFileName) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.PUBLIC_URL + '/csv/' + csvFileName);
        const csvText = await response.text();
        const { data: parsedData } = Papa.parse(csvText, { header: true });
        setData(parsedData);
      } catch (error) {
        console.error('Error parsing CSV:', error);
      }
    };
    fetchData();
  }, [csvFileName]);
  return data;
};
export default useCsvParser;