import React from "react";
import { Link } from "react-router-dom";

export default function ButtonLink({ link, message }) {
	return (
		<button
			className="border-2 border-primary text-sm text-primary p-3 mt-6 rounded-lg 
		hover:bg-primary hover:text-white hover:border-primary
		"
		>
			<Link to={link}>{message}</Link>
		</button>
	);
}
