import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AccountContext } from "../context/Account";
import moment from "moment";

import ButtonLink from "../components/ButtonLink";

export default function Dashboard() {
    const [surveys, setSurveys] = useState([]);

    const [uId, setUId] = useState("");
    const [noSurvey, setNoSurvey] = useState(false);

    const { getSession } = useContext(AccountContext);

    const [sortConfig, setSortConfig] = useState({
        key: "id",
        direction: "descending",
    });

    // Get uId
    useEffect(() => {
        getSession().then((session) => {
            const user = session.accessToken.payload.username;
            setUId(user);
			console.log("User", user)
        });
        var currentSize = JSON.stringify(sessionStorage).length;
    }, []);

    // Get surveys
    useEffect(() => {
        if (uId) {
            // fetch surveys from your lambda function
            const form_data = {
                u_id: uId,
            };

            const lambdaEndpoint =
                "https://cgs12o4yok.execute-api.us-east-1.amazonaws.com/getListOfSurveysByUserId";
            console.log(lambdaEndpoint);
            setTimeout(() => {
                fetch(lambdaEndpoint, {
                    method: "POST",
                    body: JSON.stringify(form_data),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => {
                        if (response.ok) {
                            response.json().then((data) => {
                                setSurveys(data);
                                sessionStorage.setItem(
                                    "surveys",
                                    JSON.stringify(data)
                                );
                            });
                        } else {
                            //console.log("Lambda request failed to send.");
                        }
                    })
                    .catch((error) => console.error(error));
            }, 1000);
        } else {
            // retrieve surveys from session storage
            const storedSurveys = sessionStorage.getItem("surveys");
            if (storedSurveys) {
                setSurveys(JSON.parse(storedSurveys));
            }
        }
    }, [uId]);

    useEffect(() => {
        // After 5 seconds, hide the error component
        const timeoutId = setTimeout(() => {
            setNoSurvey(true);
        }, 3000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    if (surveys.length === 0 && !noSurvey) {
        return <div>Loading...</div>;
    }

    if (noSurvey && surveys.length === 0) {
        return (
            <div className="flex flex-col items-center m-auto">
                <p>No Surveys have been created yet</p>
                <ButtonLink
                    link="/surveyCreationForm"
                    message="Create a new form"
                />
            </div>
        );
    }

    const handleSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    return (
        <div className="w-full px-5 xl:w-3/4">
            <ButtonLink
                link="/surveyCreationForm"
                message="Create a new form"
            />

            <table className="w-full border border-slate-200 mt-4 rounded-lg md:text-sm overflow-x-auto">
                <thead>
                    <tr>
                        <th>
                            <button
                                className="text-black border-none focus:border-none hover:border-none"
                                onClick={() => handleSort("name")}
                            >
                                Survey{" "}
                                {sortConfig.key === "name" ? (
                                    sortConfig.direction === "ascending" ? (
                                        "▲"
                                    ) : (
                                        "▼"
                                    )
                                ) : (
                                    <span className="text-stone-300">▲</span>
                                )}
                            </button>
                        </th>
                        <th className="w-1/4">
                            <button
                                className="text-black border-none focus:border-none hover:border-none focus-none"
                                onClick={() => handleSort("id")}
                            >
                                Date{" "}
                                {sortConfig.key === "id" ? (
                                    sortConfig.direction === "ascending" ? (
                                        "▲"
                                    ) : (
                                        "▼"
                                    )
                                ) : (
                                    <span className="text-stone-300">▲</span>
                                )}
                            </button>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {surveys
                        .sort((a, b) => {
                            if (sortConfig.key === "id") {
                                const dateA = moment(
                                    a.date,
                                    "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                ).valueOf();
                                const dateB = moment(
                                    b.date,
                                    "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                ).valueOf();

                                if (dateA < dateB) {
                                    return sortConfig.direction === "ascending"
                                        ? -1
                                        : 1;
                                }
                                if (dateA > dateB) {
                                    return sortConfig.direction === "ascending"
                                        ? 1
                                        : -1;
                                }
                            } else {
                                if (a[sortConfig.key] < b[sortConfig.key]) {
                                    return sortConfig.direction === "ascending"
                                        ? -1
                                        : 1;
                                }
                                if (a[sortConfig.key] > b[sortConfig.key]) {
                                    return sortConfig.direction === "ascending"
                                        ? 1
                                        : -1;
                                }
                            }
                            return 0;
                        })
                        .map((survey) => (
                            <tr key={survey.id}>
                                <td>
                                    <Link
                                        to={`/survey?surveyId=${survey.id}`}
                                        className="text-primary"
                                    >
                                        {survey.name}
                                    </Link>
                                </td>
                                <td>
                                    {moment(
                                        survey.date,
                                        "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                    ).isBefore(moment().subtract(5, "hours"))
                                        ? moment(
                                              survey.date,
                                              "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                          ).format("DD-MMM")
                                        : moment(
                                              survey.date,
                                              "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                          ).fromNow()}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
