import { useLocation, Outlet } from "react-router-dom";
import { Account } from "../context/Account";
import Shell from "./Shell";
import Sidebar from "./sidebar";

const Layout = () => {
    const location = useLocation();

    // Define an array of paths where the sidebar should not be rendered
    const sidebarPaths = [
        "/dashboard",
        "/survey",
        "/surveyCreationForm",
        "/setting",
        "/surveyTemplateCreationForm",
        "/surveyTemplate",
        "/surveyTemplateExample",
        "/surveyTemplateDashboard",
        "/surveyTemplateDashboardExample",
        "/aboutswiftsurvey",
    ];
    const noCenterPaths = ["/survey", "/setting"];

    // Check if the current page is in the no-sidebar paths array
    const isSidebarPage = sidebarPaths.includes(location.pathname);
    const isNoCenterPage = noCenterPaths.includes(location.pathname);

    return (
        <>
            <Account>
                <div className="flex">
                    {isSidebarPage && <Sidebar />}
                    {isSidebarPage && <Shell />}
                    <main
                        className={`${isNoCenterPage ? "" : "items-center"} ${
                            isSidebarPage ? "w-5/6 py-5" : "w-full"
                        }`}
                    >
                        <Outlet />
                    </main>
                </div>
            </Account>
        </>
    );
};

export default Layout;
