function CustomerSupport() {
    return (
        <ol>
            <li className="list-disc ml-5">
                Trigger: New support ticket or customer query in a helpdesk
                system.
            </li>
            <li className="list-disc ml-5">
                Action: Send an SMS notification to the support team or assignee
                for prompt response and issue resolution.
            </li>
        </ol>
    );
}

export default CustomerSupport;
