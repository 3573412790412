import React from "react";
import useCsvParser from "../../utils/useCsvParser";

const Table3 = () => {
	//   const data = useCsvParser(csvFileName);
	// Render your table using the parsed data
	return (
		<div>
			<h1 className="text-2xl">Pain Points</h1>
			<p>
				One of the common underestimations that companies make about
				surveys is the effort and resources required for effective
				survey implementation and analysis. Here are a few aspects that
				are often underestimated:
			</p>
			<div className="text-xs">
				<div className="py-3">
					<h3 className="font-bold">Survey Design:</h3>
					<p>
						Crafting well-designed surveys requires careful
						consideration of question structure, wording, and
						response options. Companies may underestimate the time
						and expertise needed to create clear and unbiased survey
						questions that accurately capture the desired
						information.
					</p>
				</div>
				<div className="py-3">
					<h3 className="font-bold">Survey Length:</h3>
					<p>
						Longer surveys can lead to respondent fatigue, decreased
						response rates, and lower quality data. Companies may
						underestimate the importance of keeping surveys concise
						and focused to maintain respondent engagement and obtain
						accurate insights.
					</p>
				</div>
				<div className="py-3">
					<h3 className="font-bold">Survey Distribution:</h3>
					<p>
						Reaching the right target audience and obtaining a
						sufficient number of responses can be challenging.
						Companies may underestimate the effort required to
						distribute surveys effectively, whether it's through
						email, SMS, social media, or other channels. Developing
						a thoughtful
					</p>
				</div>
				<div className="py-3">
					<h3 className="font-bold">Response Rates:</h3>
					<p>
						Obtaining high response rates can be a challenge,
						especially in today's busy and time-constrained
						environment. Companies may underestimate the difficulty
						of persuading customers or employees to participate in
						surveys and may need to employ incentives or other
						strategies to boost
					</p>
				</div>
				<div className="py-3">
					<h3 className="font-bold">Data Analysis:</h3>
					<p>
						Collecting survey responses is only the first step;
						analyzing the data and extracting meaningful insights
						require expertise and resources. Companies may
						underestimate the time and skills required to analyze
						survey data effectively, such as conducting statistical
						analysis, identifying trends,
					</p>
				</div>
				<div className="py-3">
					<h3 className="font-bold">Actionability:</h3>
					<p>
						Surveys should not be treated as standalone activities.
						Companies may underestimate the importance of
						establishing a clear plan for utilizing survey results
						to drive meaningful actions and improvements. Without a
						strategy to act on survey findings, the value of the
						survey is diminished.
					</p>
				</div>
			</div>
			<h2 className="font-bold">Our tool helps overcome pain points of surveys</h2>
			<ol className="text-xs">
				<li className="list-disc ml-4">Design wizard logic using three of the most common survey question structures</li>
				<li className="list-disc ml-4">SMS surveys reduce length and increase response rates and decrease respondent fatigue.</li>
				<li className="list-disc ml-4">Many SMS providers do not provide a user-friendly data dashboard for responses collected, as we do.</li>
				<li className="list-disc ml-4">Distribution problems are solved due to the use of a ubiquitous medium like the mobile phone.</li>
			</ol>
		</div>
	);
};
export default Table3;
