import React from "react";
import { Link } from "react-router-dom";

export default function ButtonNav({
	isLocation,
	link,
	message,
	linkClassName,
	classActiveFor,
}) {
	const isLinkActive = () => {
		if (Array.isArray(classActiveFor)) {
		  return classActiveFor.some((item) => item === isLocation.pathname);
		} else {
		  return classActiveFor === isLocation.pathname;
		}
	  };
	
	return (
		<Link
			to={link}
			className={`block w-full p-3 border-2 rounded-md 
			 focus:outline-none
			${linkClassName}
            ${
				isLinkActive()
					? "bg-white text-primary focus:outline focus:outline-offset-2 focus:outline-white"
					: "border-primary hover:border-white focus:border-white"
			}`}
		>
			{message}
		</Link>
	);
}
