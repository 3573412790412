import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
    return (
        <div className="m-0 w-9/12 flex-col">
            {/*  */}
            <div className="flex h-screen w-full bg-cover bg-center mb-10">
                <div className="flex items-end h-screen">
                    <div className="flex h-5/6 items-center justify-between gap-11">
                        <div className="flex flex-col w-2/3 gap-10">
                            <div className="">
                                <h1 className="pb-6 text-5xl font-bold leading-tight xl:text-5xl 2xl:text-6xl">
                                    SMS automation for better engagement.
                                </h1>
                                <p>
                                    Unlock multiple efficiency hacks that help
                                    you leverage SMS to solve friction points in
                                    your SMS use cases. Use API Shift as an API
                                    warehouse connected with your existing SMS
                                    provider, your current mobile plan, or sign
                                    up with one of our partners.
                                </p>
                            </div>
                            {/* Why choose us */}
                            <div className="">
                                <h2 className="font-bold text-xl">
                                    Why choose us?
                                </h2>
                                <p className="font-thin max-w-lg">
                                    We only exist because our customers agree
                                    that we make messaging automation easier,
                                    with significant ROI. Here are some examples
                                </p>
                            </div>
                        </div>
                        <div className="flex h-1/3 w-1/5 flex-col justify-center">
                            <img
                                className=""
                                src="/icons/feedback.png"
                                alt="Icon of a text bubble coming from icon below, with a smiley and a sad face inside"
                            />
                            <div className="w-1/2 -mt-3">
                                <img
                                    src="/icons/solution.png"
                                    alt="Icon of a hand presenting a key"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between">
                <div className="w-2/5">
                    <div className="h-20">
                        <h2 className="font-Economica w-fit text-white bg-primary p-3 mb-3 rounded-lg md:text-3xl xl:text-4xl 2xl:text-5xl">
                            SwiftSurvey
                        </h2>
                    </div>
                    <h2 className="text-xl font-bold">SwiftSurvey –</h2>
                    <h2 className="text-xl font-bold pb-2">Web UX ROI 400%</h2>
                    <p className="mb-3">
                        A positive website user experience leads to higher
                        conversion rates and increased customer satisfaction.
                        Research by Forrester Consulting found that investing in
                        UX design can yield an average ROI of 400%. SMS surveys
                        provide the quickest feedback loop to ROI
                    </p>
                    <p>Other examples:</p>
                    <ul>
                        <li className="list-disc ml-5">
                            Customer satisfaction survey – increasing customer
                            satisfaction by one point, increases revenue by 2.5%
                        </li>
                        <li className="list-disc ml-5">
                            Customer effort survey – customers with low effort
                            experience are 94% likely to repurchase and 88% more
                            likely to increase spend
                        </li>
                    </ul>
                </div>
                <div className="w-2/5">
                    <div className="h-20">
                        <img className="max-w-sm" src="/jungle-drum-logo.svg" />
                    </div>
                    <h2 className="text-xl font-bold">SBM Gateway – </h2>
                    <h2 className="text-xl font-bold pb-2">
                        send messages for free
                    </h2>
                    <p className="mb-3">
                        Many sole traders and micro-businesses use their mobile
                        handsets to send free txt from their message bundles –
                        but miss out on the features of paid-for services
                        charged per message.
                    </p>
                    <p>
                        Use SMBG to turn your mobile phone subscription into a
                        power tool allowing you to use SwiftSurvey and other
                        useful SMS tools, using your free txt bundles.
                    </p>
                </div>
            </div>
        </div>
    );
}
