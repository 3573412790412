import React from "react";
import useCsvParser from "../../utils/useCsvParser";

const Table2 = () => {
	const data = useCsvParser("IndustryTypeCaseStudies.csv");
	// Render your table using the parsed data
	return (
		<div>
			<h1 className="text-2xl">Industry Type Case Studies</h1>
			<p className="text pb-5">
				Here are a few additional survey case studies that showcase the
				benefits and impact of surveys in different industries:
			</p>
			<table className="text-xs border border-slate-200 rounded-lg overflow-x-auto">
				<thead>
					<tr>
						{data.length > 0 &&
							Object.keys(data[0]).map((key) => (
								<th key={key}>{key}</th>
							))}
					</tr>
				</thead>
				<tbody>
					{data.map((row, index) => (
						<tr key={index}>
							{Object.values(row).map((value, index) => (
								<td key={index}>{value}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			<p className="pt-5">
				These case studies demonstrate the real-world impact of surveys
				in improving customer satisfaction, employee engagement, product
				development, and user experience. By gathering insights through
				surveys and taking action based on the feedback received,
				organizations can drive positive outcomes and achieve their
				business objectives.
			</p>
		</div>
	);
};
export default Table2;
