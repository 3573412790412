import { useRef, useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AccountContext } from "../context/Account";
import PopUp from "../components/PopUp";

const Login = () => {
	const { authenticate } = useContext(AccountContext);

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/";

	const [popUpType, setPopUpType] = useState("");
	const [popUp, setPopUp] = useState("");

	const userRef = useRef();
	const errRef = useRef();

	const [user, setUser] = useState("");
	const [pwd, setPwd] = useState("");
	const [errMsg, setErrMsg] = useState("");
	//const [check, toggleCheck] = useToggle("persist", false);

	useEffect(() => {
		setPopUpType(location.state && location.state.popUpType);
		setPopUp(location.state && location.state.popUp);
	}, []);

	useEffect(() => {
		setErrMsg("");
	}, [user, pwd]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Authenticate user
		authenticate(user, pwd)
			.then((data) => {
				navigate("/dashboard");
			})
			.catch((err) => {
				console.error("Failed to login", err);
				setErrMsg("Incorrect password or username");
			});
	};

	return (
		<>
			{popUp && (
				<PopUp
					popUpType={popUpType}
					popUp={popUp}
					onClose={() => setPopUp(null)}
				/>
			)}
			<section className="h-screen items-center flex justify-center w-1/2">
				<form onSubmit={handleSubmit} className="bg-white flex rounded-lg">
					<div className="flex-1 text-gray-700 p-20 ">
						<p
							ref={errRef}
							className={errMsg ? "errmsg" : "offscreen"}
							aria-live="assertive"
						>
							{errMsg}
						</p>
						<h1 className="text-5xl">Sign In</h1>
						<div className="mt-6">
							<div className="pb-4">
								<label htmlFor="username">Email:</label>
								<input
									className="border-2 border-gray-500 p-2 rounded-md w-full
									focus:outline-none focus:border-primary"
									type="text"
									id="username"
									ref={userRef}
									autoComplete="on"
									onChange={(e) => setUser(e.target.value)}
									required
									autoFocus
								/>
							</div>
							<div className="pb-4">
								<label htmlFor="password">Password:</label>
								<input
									className="border-2 border-gray-500 p-2 rounded-md w-full
									focus:outline-none focus:border-primary"
									type="password"
									id="password"
									onChange={(e) => setPwd(e.target.value)}
									value={pwd}
									required
								/>
							</div>
							<button className="bg-primary text-sm text-white py-3 mt-6 rounded-lg w-full">
								Sign In
							</button>
							<div className="text-center text-sm mt-6 w-full">
								<p>Need an Account?</p>
								<Link to="/register" className="text-primary">
									Register
								</Link>
							</div>
						</div>
					</div>
				</form>
			</section>
		</>
	);
};

export default Login;
