import { useEffect, useState } from "react";
import DialogModal from "./Dialogmodal";
import NotificationModal from "./warehouseModals/NotificationModal"
import Marketing from "./warehouseModals/Marketing";
import AppointmentModal from "./warehouseModals/Appointment";
import Lead from "./warehouseModals/Lead";
import CustomerSupport from "./warehouseModals/CustomerSupport";
import ActiveDirectory from "./warehouseModals/ActiveDirectory";
import InternalComms from "./warehouseModals/InternalComms";

function InfoModal({isOpened, setIsOpened, link }) {
    const [open, setOpen] = useState(false);
    let contentComponent = '';
    let title = '';

    useEffect(() => {
        if (isOpened) {
            setOpen(true);
            setIsOpened(false);
        }
    }, [isOpened]);

    if (link === "Link1") {
		contentComponent = <NotificationModal />;
        title = 'SMS Notifications:'

	} else if (link === "Link2") {
		contentComponent = <Marketing />;
        title = 'SMS Marketing Campaigns:'

	} else if (link === "Link3") {
		contentComponent = <AppointmentModal />;
        title = 'SMS Appointment Reminders:'

	} else if (link === "Link4") {
		contentComponent = <Lead />;
        title = 'SMS Lead Generation:'

	} else if (link === "Link5") {
		contentComponent = <CustomerSupport />;
        title = 'SMS Customer Support and Helpdesk:'

	} else if (link === "Link6") {
		contentComponent = <ActiveDirectory />;
        title = 'SMS Surveys and Feedback Collection:'

	} else if (link === "Link7") {
		contentComponent = <InternalComms />;
        title = 'SMS Internal Communication:'

	} else {
		contentComponent = '';
	}

    return (
        <DialogModal
            title={title}
            isOpened={open}
            setOpen={setOpen}
            boxWidth="2/3"
        >
            {contentComponent}
        </DialogModal>
    );
}

export default InfoModal;
