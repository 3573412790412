import { useEffect, useState } from "react";
import DialogModal from "./Dialogmodal";

function Modal({ isProcessed, setIsProcessed, isOpened, setIsOpened }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (isOpened && !isProcessed) {
            setOpen(true);
            setIsOpened(false);
            setTimeout(() => {
                setIsProcessed(true);
            }, 500);
        }
    }, [isOpened]);

    useEffect(() => {}, [isProcessed]);

    return (
        <DialogModal
            title="Contact Limit Reached"
            isOpened={open}
            setOpen={setOpen}
            boxWidth="2/3"
        >
            <p>
                It appears that you are trying to add more than 100 contacts, we
                are currently limited on how many people we are able to send
                surveys to at a time.
            </p>
        </DialogModal>
    );
}

export default Modal;
