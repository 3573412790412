import React from "react";
import ButtonClose from "../../components/ButtonClose";
import ButtonOnClick from "../../components/ButtonOnClick";

export default function SurveyAnswer({
    errors,
    touched,
    Field,
    FieldArray,
    setFieldValue,
    values,
}) {
    const handleRatings = (event) => {
        console.log("success")
        const nominalFieldValue = event.target.value;
        let actualFieldValue = [];
        
        if (nominalFieldValue === "1To5") {
            actualFieldValue = ["1", "5"];
        } else if (nominalFieldValue === "1to10") {
            actualFieldValue = ["1", "10"];
        } else if (nominalFieldValue === "0To5") {
            actualFieldValue = ["0", "5"];
        } else if (nominalFieldValue === "0to10") {
            actualFieldValue = ["0", "10"];
        }

        console.log(actualFieldValue);
        setFieldValue("ratingsArray", actualFieldValue);
    };
    return (
        <>
            {/* surveyType */}
            <div className="mb-4">
                <div className="flex justify-between">
                    {errors.surveyType && touched.surveyType ? (
                        <div className="text-red-400">{errors.surveyType}</div>
                    ) : (
                        <label htmlFor="survey type" className="">
                            Survey Type:
                        </label>
                    )}
                </div>
                <div className="flex border-2 border-gray-500 mt-2 rounded-md w-full focus-within:border-primary">
                    <Field
                        name="surveyType"
                        as="select"
                        className="bg-white border-2 border-transparent m-2 w-full
						focus:outline-none"
                    >
                        <option value="" disabled>
                            Choose the type of survey
                        </option>
                        <option value="multi">Multiple Choice</option>
                        <option value="rating">Star Rating</option>
                        <option value="noYes">Yes or No</option>
                    </Field>
                </div>
            </div>
            {/* conditionally render multi, rating, noYes */}
            {(() => {
                switch (values.surveyType) {
                    case "multi":
                        return (
                            <FieldArray name="options">
                                {({ insert, remove, push }) => (
                                    <div className="w-full">
                                        {!Array.isArray(errors.options) &&
                                        touched.newOptions ? (
                                            <div className="text-red-400">
                                                {errors.options}
                                            </div>
                                        ) : Array.isArray(errors.options) &&
                                          touched.newOptions ? (
                                            <div className="text-red-400">
                                                {errors.options.map(
                                                    (error, index) => (
                                                        <div key={index}>
                                                            {error}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <label
                                                htmlFor="multiple choice"
                                                className=""
                                            >
                                                Option:
                                            </label>
                                        )}
                                        <div
                                            className="flex border-2 border-gray-500 p-2 mt-2 rounded-md w-full 
										focus-within:border-primary"
                                        >
                                            <Field
                                                name="newOptions"
                                                placeholder="Add new option"
                                                type="text"
                                                className="focus:outline-none w-11 grow"
                                                onPaste={(event) => {
                                                    const pastedData =
                                                        event.clipboardData.getData(
                                                            "text"
                                                        );
                                                    const parsed_data =
                                                        pastedData
                                                            .split("\n")
                                                            .map((value) =>
                                                                value.trim()
                                                            )
                                                            .filter(
                                                                (value) =>
                                                                    value !== ""
                                                            );
                                                    setFieldValue(
                                                        "options",
                                                        values.options.concat(
                                                            parsed_data
                                                        )
                                                    );
                                                    setTimeout(() => {
                                                        setFieldValue(
                                                            "newOptions",
                                                            ""
                                                        );
                                                    }, 0);
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        e.preventDefault();
                                                        const newOption =
                                                            e.target.value.trim();
                                                        if (newOption) {
                                                            push(newOption);
                                                            setFieldValue(
                                                                "newOptions",
                                                                ""
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                            <ButtonOnClick
                                                message="Add Option"
                                                action={() => {
                                                    const newOptions =
                                                        values.newOptions.trim();
                                                    if (newOptions) {
                                                        push(newOptions);
                                                        setFieldValue(
                                                            "newOptions",
                                                            ""
                                                        );
                                                    }
                                                }}
                                                disabled={!values.newOptions}
                                            />
                                        </div>
                                        <div className="overflow-y-auto scrollbar-hidden h-52">
                                            {values.options.length > 0 &&
                                                values.options.map(
                                                    (option, index) => (
                                                        <div
                                                            className="flex content-center border-2 border-gray-500 p-2 mt-2 rounded-md w-full focus-within:border-primary"
                                                            key={index}
                                                        >
                                                            <label
                                                                htmlFor="multiple choice options"
                                                                className="pr-2"
                                                            >
                                                                {String.fromCharCode(
                                                                    65 + index
                                                                )}
                                                                .&#41;
                                                            </label>
                                                            <Field
                                                                name={`options.${index}`}
                                                                type="text"
                                                                className="grow"
                                                            />
                                                            <ButtonClose
                                                                remove={() =>
                                                                    remove(
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                )}
                            </FieldArray>
                        );
                    case "rating":
                        return (
                            <div className="mb-4">
                                <div className="flex justify-between">
                                    {errors.surveyType && touched.surveyType ? (
                                        <div className="text-red-400">
                                            {errors.surveyType}
                                        </div>
                                    ) : (
                                        <label
                                            htmlFor="survey type"
                                            className=""
                                        >
                                            Survey Type:
                                        </label>
                                    )}
                                </div>
                                <div className="flex border-2 border-gray-500 mt-2 rounded-md w-full focus-within:border-primary">
                                    <Field
                                        name="ratingsArray"
                                        as="select"
                                        className="bg-white border-2 border-transparent m-2 w-full
						                focus:outline-none"
                                        onChange={handleRatings}
                                    >
                                        <option value="" disabled>
                                            Choose the type of survey
                                        </option>
                                        <option value="1To5">1 to 5</option>
                                        <option value="1to10">1 to 10</option>
                                        <option value="0To5">0 to 5</option>
                                        <option value="0to10">0 to 10</option>
                                    </Field>
                                </div>
                            </div>
                        );
                    case "noYes":
                        return <></>;
                    default:
                        return null;
                }
            })()}
        </>
    );
}
