import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalTable from "../components/ModalTable";

export default function HomeSwiftSurvey() {
    const [csvName, setCsvName] = useState("");
    const [isProcessed, setIsProcessed] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    const handleModalOpen = (name) => {
        setIsOpened(true);
        setCsvName(name);
		console.log(name)
    };

    return (
        <div className="w-full flex-col">
            <ModalTable
                isProcessed={isProcessed}
                setIsProcessed={setIsProcessed}
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                csvName={csvName}
            />
            <div className="h-32"></div>
            {/* Use Cases */}
            <div className="m-auto flex w-9/12 flex-col items-end gap-10">
                <div className="flex items-center gap-11 rounded bg-white p-5 drop-shadow-md">
                    <div className="flex w-1/3 flex-col content-start gap-10">
                        <div className="">
                            <h1 className="pb-3 text-2xl font-bold xl:text-3xl 2xl:text-4xl">
                                SwiftSurvey
                            </h1>
                            <p>
                                One of the first and most effective use cases
                                that benefits from automation is the simple
                                survey.
                            </p>
                        </div>
                        <div className="flex flex-col items-center w-fit">
                            <div className="flex h-full w-fit flex-col">
                                <Link
                                    to="/register"
                                    className="rounded-full bg-white px-5 py-3 text-start text-primary outline outline-2 outline-primary
									duration-300 ease-in-out hover:bg-primary hover:text-white"
                                >
                                    <span className="sr-only">
                                        Access Register page for survey app
                                    </span>
                                    Register here
                                </Link>
                            </div>
                            <p className="mt-2 text-xs">$12.99 per month</p>
                        </div>
                    </div>
                    <ol className="w-2/3">
                        <li className="list-decimal pb-3">
                            Design wizard logic using three of the most common
                            survey question structures
                        </li>
                        <li className="list-decimal pb-3">
                            SMS surveys reduce the length resulting in increased
                            response rates and decrease respondent fatigue.
                        </li>
                        <li className="list-decimal pb-3">
                            A user-friendly data dashboard to visually summarise
                            responses collected, as we do.
                        </li>
                    </ol>
                </div>
                <div className="rounded bg-white p-5 drop-shadow-md">
                    <p className="font-thin">
                        The increased engagement provided by SMS, can be hugely
                        beneficial for SMS survey response rates too. In fact,
                        research shows that text has a<span> </span>
                        <span className="font-bold">
                            209% higher response rate than email, phone and
                            social media platforms
                        </span>
                        , while on average 95% of text messages are opened
                        within 3 minutes.
                    </p>
                </div>
                <div className="flex flex-col w-full items-center">
                    <h1 className="font-bold text-lg pb-5">
                        Useful information and Case studies
                    </h1>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 mx-auto text-center">
                        <button
                            type="button"
                            className="rounded-full bg-white px-5 py-3 text-center text-primary outline outline-2 outline-primary 
									duration-300 ease-in-out hover:bg-primary hover:text-white"
                            onClick={() => handleModalOpen("table1")}
                        >
                            23 Types of surveys
                        </button>
                        
                        <button
                            type="button"
                            className="rounded-full bg-white px-5 py-3 text-center text-primary outline outline-2 outline-primary 
									duration-300 ease-in-out hover:bg-primary hover:text-white"
                            onClick={() => handleModalOpen("table3")}
                        >
                            Avoid survey pain
                        </button>
						
                        <button
                            type="button"
                            className="rounded-full bg-white px-5 py-3 text-center text-primary outline outline-2 outline-primary 
									duration-300 ease-in-out hover:bg-primary hover:text-white"
                            onClick={() => handleModalOpen("table2")}
                        >
                            Industry Case studies
                        </button>
                        <button
                            type="button"
                            className="rounded-full bg-white px-5 py-3 text-center text-primary outline outline-2 outline-primary 
									duration-300 ease-in-out hover:bg-primary hover:text-white"
                            onClick={() => handleModalOpen("table5")}
                        >
                            Use Case Studies
                        </button>
                        <button
                            type="button"
                            className="rounded-full bg-white px-5 py-3 text-center text-primary outline outline-2 outline-primary 
									duration-300 ease-in-out hover:bg-primary hover:text-white"
                            onClick={() => handleModalOpen("table4")}
                        >
                            SMS and Surveys
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
