function ActiveDirectory() {
    return (
        <ol>
            <li className="list-disc ml-5">
                Trigger: Event in one application (e.g., new customer, completed
                purchase).
            </li>
            <li className="list-disc ml-5">
                Action: Send an SMS containing a survey link or question to
                gather feedback or conduct customer satisfaction surveys.
            </li>
        </ol>
    );
}

export default ActiveDirectory;
