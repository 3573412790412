import React from "react";

const Stepper = ({ step }) => {
	return (
		<div className="flex justify-center mb-5">
			<div className="flex items-center justify-between py-4 px-8 border-b">
				<div className="flex items-center">
					<div
						className={`rounded-full h-8 w-8 flex items-center justify-center text-white font-bold ${
							step >= 1 ? "bg-primary" : "bg-gray-300"
						}`}
					>
						1
					</div>
					<div
						className={`h-0 w-16 border-t ${
							step > 1 ? "border-primary" : "border-gray-300"
						} mx-3`}
					></div>
					<div
						className={`rounded-full h-8 w-8 flex items-center justify-center text-white font-bold ${
							step >= 2 ? "bg-primary" : "bg-gray-300"
						}`}
					>
						2
					</div>
				</div>
			</div>
		</div>
	);
};

export default Stepper;
