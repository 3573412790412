import React from "react";

const fecthT = (u_id, setTemplates) => {
    const form_data = {
        u_id: u_id,
    };

    const lambdaEndpoint =
        "https://cgs12o4yok.execute-api.us-east-1.amazonaws.com/getListofTemlates";

    console.log(lambdaEndpoint);

    fetch(lambdaEndpoint, {
        method: "POST",
        body: JSON.stringify(form_data),
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "Content-Type",
        },
    })
        .then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setTemplates(data);
                    sessionStorage.setItem(
                        "surveyTemplatesExamples",
                        JSON.stringify(data)
                    );
                });
            } else {
                console.log("Lambda request failed to send.");
            }
        })
        .catch((error) => console.error(error));
};

export default function fetchExampleTemplates(u_id, setTemplates, refresh) {
    if (u_id) {
        if (refresh) {
            fecthT(u_id, setTemplates);
        } else {
            // retrieve templates from session storage
            const storedSurveys = sessionStorage.getItem("surveyTemplatesExamples");
            if (storedSurveys) {
                setTemplates(JSON.parse(storedSurveys));
                // fetch templates from your lambda function
                setTimeout(() => {
                    fecthT(u_id, setTemplates);
                }, 1000);
            } else {
                fecthT(u_id, setTemplates);
            }
        }
    }
    return;
}
