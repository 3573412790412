import React from "react";
import { Link } from "react-router-dom";

export default function ButtonSubNav({
	isLocation,
	link,
	message,
	linkClassName,
}) {
	return (
		<Link
			to={link}
			className={`block w-full p-3 border-b-2 mt-6
			 focus:outline-none
			${linkClassName}
            ${
				isLocation.pathname === link
				? "border-primary"
				: "border-gray-300 hover:border-gray-500 focus:border-gray-500"
			}`}
		>
			{message}
		</Link>
	);
}
