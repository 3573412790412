import React from "react";

export default function ButtonClose({remove}) {
	return (
		<button
			type="button"
			className="px-2 rounded-md border-2 border-transparent text-primary text-sm h-full
            hover:border-primary"
			onClick={remove}
		>
			X
		</button>
	);
}
