import React from "react";
import { Link } from "react-router-dom";

export default function HomeHow() {
	return (
		<div className="m-0 w-full flex-col">
			{/* How */}
			<div className="m-auto flex h-screen w-9/12 items-end">
				<div className="flex h-5/6 w-full items-center gap-11">
					<div className="flex flex-1 flex-col content-start gap-6 rounded bg-white p-5 drop-shadow-md 2xl:gap-10">
						<div>
							<h1 className="pb-2 text-xl font-bold 2xl:pb-4 2xl:text-2xl 22xl:text-3xl">
								How to get started
							</h1>
							<div>
								<div className="mb-2 flex w-max items-center 2xl:mb-5">
									<div className="mx-2 flex h-8 w-8 items-center justify-center rounded-full bg-primary 2xl:mx-5 2xl:h-12 2xl:w-12">
										<h2 className="font-bold text-white 2xl:text-xl">
											1
										</h2>
									</div>
									<p>Register to create an account.</p>
								</div>
								<div className="my-2 flex w-max items-center 2xl:my-5">
									<div className="mx-2 flex h-8 w-8 items-center justify-center rounded-full bg-primary 2xl:mx-5 2xl:h-12 2xl:w-12">
										<h2 className="font-bold text-white 2xl:text-xl">
											2
										</h2>
									</div>
									<p>
										Collect your current sms provider API
										keys
									</p>
								</div>
								<div className="my-2 flex w-max items-center 2xl:my-5">
									<div className="mx-2 flex h-8 w-8 items-center justify-center rounded-full bg-primary 2xl:mx-5 2xl:h-12 2xl:w-12">
										<h2 className="font-bold text-white 2xl:text-xl">
											3
										</h2>
									</div>
									<p>
										Copy & paste the API key in API Shift
										profile
									</p>
								</div>
								<div className="my-2 flex w-max items-center 2xl:my-5">
									<div className="mx-2 flex h-8 w-8 items-center justify-center rounded-full bg-primary 2xl:mx-5 2xl:h-12 2xl:w-12">
										<h2 className="font-bold text-white 2xl:text-xl">
											4
										</h2>
									</div>
									<p>
										Start using your new tool wizard or
										template
									</p>
								</div>
							</div>
						</div>
						<div>
							<h1 className="pb-2 text-xl font-bold 2xl:pb-4 2xl:text-2xl 22xl:text-3xl">
								Advanced usage
							</h1>
							<div className="mb-2 flex w-max items-center 2xl:mb-5">
								<div className="mx-2 flex h-8 w-8 items-center justify-center rounded-full bg-primary 2xl:mx-5 2xl:h-12 2xl:w-12">
									<h2 className="font-bold text-white 2xl:text-xl">
										5
									</h2>
								</div>
								<p className="max-w-sm">
									Add more automation tools as you need them
									by adding them to your profile.
								</p>
							</div>
						</div>
					</div>
					<div className="flex h-2/3 flex-col items-end justify-center">
						<img
							src="/icons/ui-design.png"
							alt="Icon of a text bubble coming from icon below, with a smiley and a sad face inside"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
