import React, { useEffect, useState } from "react";
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser,
} from "amazon-cognito-identity-js";
import { useFormik } from "formik";
import * as Yup from "yup";
import ButtonFullWidth from "../components/ButtonFullWidth";

export default function ChangePassword({ setPopUp, setPopUpType }) {
    const PWD_REGEX = /^(?=.*[a-z])(?=.*[0-9]).{6,52}$/;
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (values, { resetForm }) => {
        console.log("submit");
        setIsLoading(true);
        const poolData = {
            UserPoolId: "ap-southeast-2_2r64XeNdz",
            ClientId: "6cchhpsdkkb9ps5oh7nf4douhe",
        };

        const userPool = new CognitoUserPool(poolData);
        const user = userPool.getCurrentUser();

        if (!user) {
            setPopUpType("error");
            setPopUp("User not authenticated");
            return;
        }

        const authenticationDetails = new AuthenticationDetails({
            Username: user.getUsername(),
            Password: formik.values.currentPassword,
        });

        const cognitoUser = new CognitoUser({
            Username: user.getUsername(),
            Pool: userPool,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: () => {
                cognitoUser.changePassword(
                    formik.values.currentPassword,
                    formik.values.newPassword,
                    (err, result) => {
                        if (err) {
                            setPopUpType("error");
                            setPopUp(
                                "An error occurred while changing the password."
                            );
                            setIsLoading(false);
                        } else {
                            setPopUpType("success");
                            setPopUp("Password changed successfully!");
                            setIsLoading(false);
                            resetForm();
                        }
                    }
                );
            },
            onFailure: (err) => {
                setPopUpType("error");
                setPopUp(
                    "That does not appear to be the correct Current Password"
                );
                setIsLoading(false);
            },
        });
    };

    const initialValues = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    };

    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string().required("Current password is required"),
        newPassword: Yup.string()
            .matches(
                PWD_REGEX,
                "Password must contain at least one lowercase letter, one digit, and be 6 to 52 characters long"
            )
            .required("New password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
            .required("Confirm password is required"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        console.log("isLoading", isLoading);
    }, [isLoading]);

    return (
        <div className="bg-white rounded-lg py-6 px-12 w-full text-sm">
            <form
                className="flex flex-col gap-3"
                onSubmit={formik.handleSubmit}
            >
                <div>
                    {formik.errors.currentPassword &&
                    formik.touched.currentPassword ? (
                        <>
                            <div className="text-red-400">
                                {formik.errors.currentPassword}
                            </div>
                            <label
                                className="sr-only"
                                htmlFor="currentPassword"
                            >
                                Current Password
                            </label>
                        </>
                    ) : (
                        <label htmlFor="currentPassword">
                            Current Password
                        </label>
                    )}
                    <input
                        className="border-2 border-gray-500 p-2 mt-2 rounded-md w-full
                            focus:outline-none focus:border-primary"
                        type="password"
                        id="currentPassword"
                        name="currentPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.currentPassword}
                    />
                </div>

                <div>
                    {formik.errors.newPassword && formik.touched.newPassword ? (
                        <>
                            <div className="text-red-400">
                                {formik.errors.newPassword}
                            </div>
                            <label className="sr-only" htmlFor="newPassword">
                                New Password
                            </label>
                        </>
                    ) : (
                        <label htmlFor="newPassword">New Password</label>
                    )}
                    <input
                        className="border-2 border-gray-500 p-2 mt-2 rounded-md w-full
                            focus:outline-none focus:border-primary"
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.newPassword}
                    />
                </div>

                <div>
                    {formik.errors.confirmPassword &&
                    formik.touched.confirmPassword ? (
                        <>
                            <div className="text-red-400">
                                {formik.errors.confirmPassword}
                            </div>
                            <label
                                className="sr-only"
                                htmlFor="confirmPassword"
                            >
                                Confirm Password
                            </label>
                        </>
                    ) : (
                        <label htmlFor="confirmPassword">
                            Confirm Password
                        </label>
                    )}
                    <input
                        className="border-2 border-gray-500 p-2 mt-2 rounded-md w-full
                            focus:outline-none focus:border-primary"
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                    />
                </div>

                <ButtonFullWidth
                    className="border-gray-400 bg-gray-400 text-white"
                    applyClassName={
                        Object.keys(formik.errors).length !== 0 ||
                        Object.keys(formik.touched).length === 0
                    }
                    disabled={
                        Object.keys(formik.errors).length !== 0 ||
                        Object.keys(formik.touched).length === 0 ||
                        isLoading
                    }
                    type="submit"
                    loading={isLoading}
                    message="Change Password"
                />
            </form>
        </div>
    );
}
