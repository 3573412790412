function InternalComms() {
    return (
        <ol>
            <li className="list-disc ml-5">
                Trigger: Event in a team collaboration or internal communication
                tool (e.g., new message, task assignment).
            </li>
            <li className="list-disc ml-5">
                Action: Send an SMS notification to team members for important
                updates or urgent communication.
            </li>
        </ol>
    );
}

export default InternalComms;
