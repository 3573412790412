import React, { useState, useContext, useEffect } from "react";
import PopUp from "../components/PopUp";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import fetchTemplates from "../utils/fetchTemplates";

import { AccountContext } from "../context/Account";
import * as AWS from "aws-sdk";
import PhoneMock from "../components/PhoneMock";
import S1SurveyCreationForm from "../components/form/S1SurveyCreationForm";
import S2SurveyCreationForm from "../components/form/S2SurveyCreationForm";
import ButtonFullWidth from "../components/ButtonFullWidth";
import Stepper from "../components/form/Stepper";

//import { CognitoIdentityServiceProvider } from "aws-sdk";

export default function SurveyCreationForm() {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [api_key, set_api_key] = useState("");
	const [api_secret, set_api_secret] = useState("");
	const [api_vendor, set_api_vendor] = useState("");
	const [u_id, set_u_id] = useState("");

	const [templates, setTemplates] = useState([]);

	const [s1Form, sets1Form] = useState({
		contacts: [],
		personalisation: [],
		fileName: "",
	});
	const [s2Form, sets2Form] = useState({
		formSurveyName: "",
		formMessage: "",
		formSurveyType: "",
		formRatingsData: "",
		formOptionsData: [],
	});
	

	const { getSession } = useContext(AccountContext);

	const [popUp, setPopUp] = useState(null);
	const [popUpType, setPopUpType] = useState(null);
	const navigate = useNavigate();

	const [step, setStep] = useState(1);

	const handleNextStep = () => {
		setStep((prevStep) => prevStep + 1);
	};

	const handlePrevStep = () => {
		setStep((prevStep) => prevStep - 1);
	};

	useEffect(() => {
		fetchTemplates(u_id, setTemplates);
	}, [u_id]);

	// // Retrieving stored Templates
	// useEffect(() => {
	// 	const storedSurveys = JSON.parse(
	// 		sessionStorage.getItem("surveyTemplates")
	// 	);
	// 	console.log("stored", storedSurveys);
	// 	if (storedSurveys) {
	// 		setTemplates(storedSurveys);
	// 	}
	// }, []);

	// Getting API keys and accessToken
	useEffect(() => {
		getSession().then((session) => {
			const token = session.accessToken.jwtToken.toString();

			const user = session.accessToken.payload.username;
			set_u_id(user);

			const cognitoidentityserviceprovider =
				new AWS.CognitoIdentityServiceProvider({
					region: "ap-southeast-2",
					apiVersion: "2016-04-18",
				});

			var params = {
				AccessToken: token,
			};

			// Get API keys
			cognitoidentityserviceprovider.getUser(
				params,
				function (err, data) {
					if (err) console.log(err, err.stack);
					else {
						const customAttributeKey = data.UserAttributes.find(
							(attr) => attr.Name === "custom:api_key"
						);
						const customAttributeSecret = data.UserAttributes.find(
							(attr) => attr.Name === "custom:api_secret"
						);
						const customAttributeVendor = data.UserAttributes.find(
							(attr) => attr.Name === "custom:vendor"
						);
						set_api_key(customAttributeKey.Value);
						set_api_secret(customAttributeSecret.Value);
						set_api_vendor(customAttributeVendor.Value);
					}
				}
			);
		});
	}, []);

	if (!templates) {
		return <div>loading</div>;
	}

	return (
		<>
			{popUp && (
				<PopUp
					popUpType={popUpType}
					popUp={popUp}
					onClose={() => setPopUp(null)}
				/>
			)}
			{step === 1 && (
				<S1SurveyCreationForm
					step={step}
					setStep={setStep}
					s1Form={s1Form}
					sets1Form={sets1Form}
				/>
			)}
			{step === 2 && (
				<S2SurveyCreationForm
					step={step}
					setStep={setStep}
					s1Form={s1Form}
					s2Form={s2Form}
					sets2Form={sets2Form}
				/>
			)}
			{step === 3 && (
				<Formik
					initialValues={{
						noYesArray: ["no", "yes"],
					}}
					onSubmit={(values) => {
						setIsSubmitting(true);
						let numberedOptions = [];

						if (s2Form.formSurveyType === "multi") {
							numberedOptions = s2Form.formOptionsData.map(
								(option, index) =>
									`${String.fromCharCode(
										65 + index
									)}.) ${option}`
							);
						} else if (s2Form.formSurveyType === "rating") {
							numberedOptions = s2Form.formRatingsData;
						} else {
							numberedOptions = values.noYesArray;
						}

						const formData = {
							survey_name: s2Form.formSurveyName,
							message: s2Form.formMessage,
							survey_type: s2Form.formSurveyType,
							options: numberedOptions,
							contact: s1Form.contacts,
							u_id: u_id,
							api_key: api_key,
							api_secret: api_secret,
							api_vendor: api_vendor,
							personalisation: s1Form.personalisation,
						};
						console.log(JSON.stringify(formData, null, 2));

						const lambdaEndpoint =
							"https://cgs12o4yok.execute-api.us-east-1.amazonaws.com/sendingSurveyMessage";
						console.log(lambdaEndpoint)
						// send the form data to the Lambda function
						fetch(lambdaEndpoint, {
							method: "POST",
							body: JSON.stringify(formData),
							headers: {
								"Content-Type": "application/json"
							},
						})
							.then((response) => {
								if (response.ok) {
									navigate("/dashboard");
								} else {
									setPopUpType("error");
									setPopUp(
										"An error occurred. Please try again later."
									);
									setIsSubmitting(false);
								}
							})
							.catch((error) => {
								console.error(error);
								setIsSubmitting(false);
							});
					}}
				>
					{({
						errors,
						touched,
						values,
						setFieldValue,
						handleBlur,
					}) => (
						<Form className="flex flex-row gap-x-9 h-full xl:w-9/12">
							<div className="flex flex-col p-5 rounded bg-white drop-shadow-md w-full">
								<Stepper step={step} />

								<>
									<div className="grow flex flex-col 2xl:flex-row gap-x-5 w-full">
										<div className="flex gap-5 mt-5 w-full">
											<div className="w-1/2 text-lg">
												<h2 className="">
													You are sending this survey
													to:
												</h2>
												<div className="flex gap-2">
													<h2 className="font-bold">
														{s1Form.contacts.length}
													</h2>
													<h2>
														{s1Form.contacts
															.length > 1 ? (
															<p>users</p>
														) : (
															<p>user</p>
														)}
													</h2>
												</div>
											</div>
											<div className="w-1/2">
												<div className="sticky top-5 h-fit">
													<PhoneMock
														message={
															s2Form.formMessage
														}
														surveyType={
															s2Form.formSurveyType
														}
														options={
															s2Form.formOptionsData
														}
														ratingsArray={
															s2Form.formRatingsData
														}
														noYesArray={
															values.noYesArray
														}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="flex mx-12 gap-x-5">
										<ButtonFullWidth
											type="button"
											message="Previous"
											action={handlePrevStep}
											className={`${
												step === 1
													? "bg-gray-300 text-gray-500 border-gray-300"
													: null
											}`}
											applyClassName={step === 1}
											disabled={step === 1}
										/>
										<ButtonFullWidth
											type="submit"
											message="Submit"
											disabled={isSubmitting}
											loading={true}
										/>
									</div>
								</>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
}
