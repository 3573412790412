import React, { useState } from "react";
import PopUp from "../PopUp";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { FaUpload } from "react-icons/fa";

import ButtonClose from "../ButtonClose";
import ButtonOnClick from "../ButtonOnClick";
import ButtonFullWidth from "../ButtonFullWidth";

//import { CognitoIdentityServiceProvider } from "aws-sdk";

export default function S1Registration({
    step,
    handlePrevStep,
    handleNextStep,
    s1Form,
    sets1Form,
}) {
    const [popUp, setPopUp] = useState(null);
    const [popUpType, setPopUpType] = useState(null);

    // const handleNextStep = () => {
    //     setStep((prevStep) => prevStep + 1);
    // };

    // const handlePrevStep = () => {
    //     setStep((prevStep) => prevStep - 1);
    // };

    return (
        <>
            {popUp && (
                <PopUp
                    popUpType={popUpType}
                    popUp={popUp}
                    onClose={() => setPopUp(null)}
                />
            )}
            <Formik
                initialValues={{
                    vendor: s1Form.vendor,
                    apiKey: s1Form.apiKey,
                    apiSecret: s1Form.apiSecret,
                }}
                validationSchema={Yup.object().shape({
                    vendor: Yup.string().required("Required"),
                    apiKey: Yup.string().required("Required"),
                    apiSecret: Yup.string().when("vendor", {
                        is: (value) => value !== "SBM",
                        then: (schema) => schema.required("Required"),
                    }),
                })}
                onSubmit={(values) => {
                    const vendor = values.vendor;
                    const apiKey = values.apiKey;
                    const apiSecret = values.apiSecret;

                    sets1Form({ vendor, apiKey, apiSecret });
                    handleNextStep();
                }}
            >
                {({ errors, touched, values }) => (
                    <Form className="">
                        <div className="flex flex-col">
                            {/* vendor */}
                            <div className="pb-3">
                                {errors.vendor && touched.vendor ? (
                                    <div className="text-red-400">
                                        {errors.vendor}
                                    </div>
                                ) : (
                                    <label htmlFor="vendor_select" className="">
                                        Select Vendor:
                                    </label>
                                )}
                                <div className="flex border-2 border-gray-500 mt-1 rounded-md w-full focus-within:border-primary">
                                    <Field
                                        as="select"
                                        name="vendor"
                                        className="bg-white border-2 border-transparent m-2 w-full
										focus:outline-none"
                                    >
                                        <option value="" disabled>
                                            Select the assosciated Vendor
                                        </option>
                                        <option value="eTXT">eTXT</option>
                                        <option value="MessageMedia">
                                            MessageMedia
                                        </option>
                                        <option value="SBM">SBM - Under Development</option>
                                    </Field>
                                </div>
                            </div>

                            {values.vendor !== "" && (
                                <>
                                    {/* apiKey */}
                                    <div className="pb-3">
                                        {errors.apiKey && touched.apiKey ? (
                                            <div className="text-red-400">
                                                {errors.apiKey}
                                            </div>
                                        ) : (
                                            <label
                                                htmlFor="api_key"
                                                className=""
                                            >
                                                API Key:
                                            </label>
                                        )}
                                        <Field
                                            name="apiKey"
                                            placeholder="Enter the API Key"
                                            className="border-2 border-gray-500 p-2 mt-1 rounded-md w-full 
											focus:outline-none focus:border-primary"
                                        />
                                    </div>
                                    {/* apiSecret */}
                                    {values.vendor !== "SBM" && (
                                        <div className="pb-3 xl:pb-9">
                                            {errors.apiSecret &&
                                            touched.apiSecret ? (
                                                <div className="text-red-400">
                                                    {errors.apiSecret}
                                                </div>
                                            ) : (
                                                <label
                                                    htmlFor="api_secret"
                                                    className=""
                                                >
                                                    API Secret:
                                                </label>
                                            )}
                                            <Field
                                                name="apiSecret"
                                                placeholder="Enter the API Secret"
                                                className="border-2 border-gray-500 p-2 mt-1 rounded-md w-full 
											focus:outline-none focus:border-primary"
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="grow flex flex-col 2xl:flex-row gap-x-5 w-full"></div>
                            <div>
                                <div className="flex gap-x-5 mx-12">
                                    <ButtonFullWidth
                                        type="button"
                                        message="Previous"
                                        action={handlePrevStep}
                                        className={`${
                                            step === 1
                                                ? "bg-gray-300 text-gray-500 border-gray-300"
                                                : null
                                        }`}
                                        applyClassName={step === 1}
                                        disabled={step === 1}
                                    />
                                    <ButtonFullWidth
                                        type="submit"
                                        message="Next"
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
