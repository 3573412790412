import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "../context/Account";
import { useNavigate } from "react-router-dom";
import ButtonOnClick from "../components/ButtonOnClick";
import ChangePassword from "../components/ChangePassword";
import PopUp from "../components/PopUp";
import S1Registration from "../components/registrationForm/S1Registration";
import S2Registration from "../components/registrationForm/S2Registration";
import Stepper from "../components/Stepper2Step";
import ChangeApiKey from "../components/ChangeApiKey";

function Setting() {
    const [popUp, setPopUp] = useState(null);
    const [popUpType, setPopUpType] = useState(null);

    const { logout } = useContext(AccountContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate("/login");
    };

    // API Key
    const { getSession } = useContext(AccountContext);

    const [apiKey, setApiKey] = useState("");
    const [apiVendor, setApiVendor] = useState("");

    const handleGetSession = () => {
        getSession().then((session) => {
            const apiKey = session.idToken.payload["custom:api_key"];
            const vendor = session.idToken.payload["custom:vendor"];
            setApiKey(apiKey);
            setApiVendor(vendor);
        });
    };

    useEffect(() => {
        handleGetSession();
    }, []);

    const [s1Form, sets1Form] = useState({
        vendor: "",
        apiKey: "",
        apiSecret: "",
    });

    const [step, setStep] = useState(0);

    const handleNextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const handlePrevStep = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const handleCancelChangeApiKey = () => {
        setStep(0);
    };

    useEffect(() => {
        if (step === 3) {
            const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

            const poolData = {
                UserPoolId: "ap-southeast-2_2r64XeNdz",
                ClientId: "6cchhpsdkkb9ps5oh7nf4douhe",
            };
            const userPool = new AmazonCognitoIdentity.CognitoUserPool(
                poolData
            );

            // Get the current authenticated user
            const cognitoUser = userPool.getCurrentUser();

            let userSession;
            cognitoUser.getSession((err, session) => {
                if (err) {
                    console.error("Error getting user session:", err);
                    return;
                }

                // Get user attributes from the session
                const attributes = session.getIdToken().payload;
                userSession = session;
            });

            if (s1Form.vendor === "SBM") {
                s1Form.apiSecret = "none";
            }

            const attributeList = [];
            let attribute = new AmazonCognitoIdentity.CognitoUserAttribute({
                Name: "custom:api_key",
                Value: s1Form.apiKey,
            });
            attributeList.push(attribute);

            attribute = new AmazonCognitoIdentity.CognitoUserAttribute({
                Name: "custom:api_secret",
                Value: s1Form.apiSecret,
            });
            attributeList.push(attribute);

            attribute = new AmazonCognitoIdentity.CognitoUserAttribute({
                Name: "custom:vendor",
                Value: s1Form.vendor,
            });
            attributeList.push(attribute);

            cognitoUser.updateAttributes(attributeList, (err, result) => {
                if (err) {
                    console.error("Error updating attributes:", err);
                    return;
                }
            });

            let updatedAttributes;

            cognitoUser.getUserData((err, userData) => {
                if (err) {
                    console.error("Error getting user data:", err);
                    return;
                }

                // Get the updated user attributes from the fetched user data
                updatedAttributes = userData.UserAttributes.reduce(
                    (acc, attribute) => {
                        acc[attribute.Name] = attribute.Value;
                        return acc;
                    },
                    {}
                );
            });

            handleCancelChangeApiKey();
            setTimeout(() => {
                userSession.getIdToken().payload = {
                    ...userSession.getIdToken().payload,
                    ...updatedAttributes,
                };
            }, 300);
            setTimeout(() => {
                window.location.reload(true);
            }, 500);
        }
    }, [step]);

    return (
        <>
            {popUp && (
                <PopUp
                    popUpType={popUpType}
                    popUp={popUp}
                    onClose={() => setPopUp(null)}
                />
            )}
            <div className="px-5">
                <div className="ml-auto w-fit">
                    <ButtonOnClick message="Log Out" action={handleLogout} />
                </div>
                <div className="flex flex-col lg:flex-row gap-4 items-start mt-5">
                    <section className="max-w-xl w-full flex-1 items-center flex justify-center rounded-lg drop-shadow-md text-gray-700">
                        <ChangePassword
                            setPopUp={setPopUp}
                            setPopUpType={setPopUpType}
                        />
                    </section>
                    <section className="max-w-xl w-full flex-1 items-center flex justify-center rounded-lg drop-shadow-md text-gray-700">
                        <div className="bg-white relative rounded-lg py-6 px-12 w-full text-sm">
                            {step === 0 && (
                                <ChangeApiKey
                                    step={step}
                                    handlePrevStep={handlePrevStep}
                                    handleNextStep={handleNextStep}
                                    apiKey={apiKey}
                                    apiVendor={apiVendor}
                                />
                            )}
                            {step >= 1 && (
                                <>
                                    <div className="w-full flex justify-end">
                                        <div className="-mr-9">
                                            <ButtonOnClick
                                                message="Cancel"
                                                action={
                                                    handleCancelChangeApiKey
                                                }
                                            />
                                        </div>
                                    </div>
                                    <Stepper step={step} />
                                </>
                            )}
                            {step === 1 && (
                                <S1Registration
                                    step={step}
                                    handlePrevStep={handlePrevStep}
                                    handleNextStep={handleNextStep}
                                    s1Form={s1Form}
                                    sets1Form={sets1Form}
                                />
                            )}
                            {step === 2 && (
                                <S2Registration
                                    step={step}
                                    handlePrevStep={handlePrevStep}
                                    handleNextStep={handleNextStep}
                                    s1Form={s1Form}
                                    handleGetSession={handleGetSession}
                                />
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Setting;
