function Lead() {
    return (
        <ol>
            <li className="list-disc ml-5">
            Trigger: New lead captured in a CRM or form submission.
            </li>
            <li className="list-disc ml-5">
            Action: Send an SMS with a personalized message and call-to-action to the lead for follow-up or nurturing.
            </li>
        </ol>
    );
}

export default Lead;
