function Marketing() {
    return (
        <ol>
            <li className="list-disc ml-5">
                Trigger: Event in a marketing automation platform (e.g., new
                subscriber, completed action).
            </li>
            <li className="list-disc ml-5">
                Action: Send targeted SMS messages to subscribers as part of a
                marketing campaign or promotional offer.
            </li>
        </ol>
    );
}

export default Marketing;
