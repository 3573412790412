import React from "react";
import useCsvParser from "../../utils/useCsvParser";

const Table5 = () => {
	const data = useCsvParser("SurveyTypeCaseStudies.csv");
	// Render your table5 using the parsed data
	return (
		<div>
			<h1 className="text-2xl">Survey Type Case Studies</h1>
			<p className="text pb-5">
				Several survey types have well-documented business cases that
				demonstrate their benefits. Here are a few examples:
			</p>
			<table className="text-xs border border-slate-200 rounded-lg overflow-x-auto">
				<thead>
					<tr>
						{data.length > 0 &&
							Object.keys(data[0]).map((key) => (
								<th key={key}>{key}</th>
							))}
					</tr>
				</thead>
				<tbody>
					{data.map((row, index) => (
						<tr key={index}>
							{Object.values(row).map((value, index) => (
								<td key={index}>{value}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			<p className="pt-5">
				These are just a few examples of survey types with supporting
				business cases. It's important to note that the benefits can
				vary based on the specific industry, organization, and survey
				implementation. Conducting surveys and analyzing the results in
				a thoughtful and actionable manner can lead to tangible business
				outcomes and competitive advantages.
			</p>
		</div>
	);
};
export default Table5;
