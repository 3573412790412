import { useEffect, useRef } from "react";
import ButtonOnClick from "./ButtonOnClick";

const isClickInsideRectangle = (e, element) => {
	const r = element.getBoundingClientRect();

	return (
		e.clientX > r.left &&
		e.clientX < r.right &&
		e.clientY > r.top &&
		e.clientY < r.bottom
	);
};

const DialogModal = ({ title, isOpened, setOpen, children, boxWidth }) => {
	const ref = useRef(null);

    const onClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (isOpened) {
			ref.current?.showModal();
			document.body.classList.add("modal-open"); // prevent bg scroll
		} else {
			ref.current?.close();
			document.body.classList.remove("modal-open");
		}
	}, [isOpened]);

	return (
		<dialog
			className={`w-${boxWidth} rounded-8px border-1 border-gray-800 shadow-md`}
			ref={ref}
			onCancel={onClose}
			onClick={(e) =>
				ref.current &&
				!isClickInsideRectangle(e, ref.current) &&
				onClose()
			}
		>
			<h1 className="font-bold text-xl">{title}</h1>

			{children}

			<div className="flex w-full justify-center mt-3">
                <ButtonOnClick message="Close" action={onClose}/>
			</div>
		</dialog>
	);
};

export default DialogModal;
