import React from "react";

export default function SurveyName({
	errors,
	touched,
	Field,
}) {
	return (
		<>
			{/* surveyName */}
			<div className="pb-9 xl:pb-4">
				{errors.surveyName && touched.surveyName ? (
					<div className="text-red-400">{errors.surveyName}</div>
				) : (
					<label htmlFor="survey name" className="">
						Survey Name:
					</label>
				)}
				<Field
					name="surveyName"
					placeholder="Enter the Survey Name"
					className="border-2 border-gray-500 p-2 mt-2 rounded-md w-full 
					focus:outline-none focus:border-primary"
				/>
			</div>
		</>
	);
}
