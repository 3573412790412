import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";

import PhoneMock from "../PhoneMock";
import SurveyQuestion from "./SurveyQuestion";
import SurveyAnswer from "./SurveyAnswer";
import ButtonFullWidth from "../ButtonFullWidth";
import SurveyName from "../form/SurveyName";
import Stepper from "../form/Stepper";

//import { CognitoIdentityServiceProvider } from "aws-sdk";

export default function SurveyCreationForm({
    step,
    setStep,
    s1Form,
    s2Form,
    sets2Form,
}) {
    const [templates, setTemplates] = useState([]);
    // const [surveyTypeData, setSurveyTypeData] = useState("");

    const handleNextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const handleS2FormSave = (values) => {

        const formSurveyName = values.surveyName ? values.surveyName : "";
        const formMessage = values.message ? values.message : "";
        const formSurveyType = values.surveyType ? values.surveyType : "";
        const formOptionsData = values.options.length > 0 ? values.options : [];
        const formRatingsData =
            values.ratingsArray ? values.ratingsArray : "";
        const formTemplates = values.template ? values.template : "";

        const formData = {
            formSurveyName: formSurveyName,
            formMessage: formMessage,
            formSurveyType: formSurveyType,
            formOptionsData: formOptionsData,
            formRatingsData: formRatingsData,
            formTemplates: formTemplates,
        };

        sets2Form({
            formSurveyName,
            formMessage,
            formSurveyType,
            formOptionsData,
            formRatingsData,
            formTemplates,
        });
    };
    
    const handlePersonalisation = (event, setFieldValue, values) => {
        const header = event.target.value;
        setFieldValue("template", header);
        const message = values.message;
        console.log(message);
        setFieldValue("message", message + "[[ " + header + " ]]");
    };

    const handlePrevStep = (values) => {
        handleS2FormSave(values);

        setStep((prevStep) => prevStep - 1);
    };

    // Retrieving stored Templates
    useEffect(() => {
        const storedSurveys = JSON.parse(
            sessionStorage.getItem("surveyTemplates")
        );

        if (storedSurveys) {
            setTemplates(storedSurveys);
        }
    }, []);

    const handleTemplate = (event, setFieldValue) => {
        const selectedTemplateId = event.target.value;

        let templateData = [];
        if (templates) {
            templateData = templates.filter(
                (item) => item.id === selectedTemplateId
            );
            // setSurveyTypeData(templateData[0].answers);
        }

        templateData = templateData[0];

        if (templateData.surveyName !== "Null") {
            setFieldValue("surveyName", templateData.surveyName);
        }
        if (templateData.question !== "Null") {
            setFieldValue("message", templateData.question);
        }
        if (templateData.type !== "Null") {
            setFieldValue("surveyType", templateData.type);

            if (templateData.type === "multi") {
                const array = templateData.answers.split("/%/%/%");
                setFieldValue("options", array);
            } else if (templateData.type === "rating") {
                const array = templateData.answers.split("/%/%/%");
                setFieldValue("ratingsArray", array);
            } else {
                const array = templateData.answers.split("/%/%/%");
                setFieldValue("noYesArray", array);
            }
        }
    };

    if (!templates) {
        return <div>loading</div>;
    }

    return (
        <>
            <Formik
                initialValues={{
                    surveyName: s2Form.formSurveyName,
                    message: s2Form.formMessage,
                    surveyType: s2Form.formSurveyType,
                    ratingsArray: s2Form.formRatingsData,
                    options: s2Form.formOptionsData,
                    noYesArray: ["no", "yes"],
                    newOptions: "",
                    template: "",
                    personalisation: s1Form.personalisation,
                }}
                validationSchema={Yup.object({
                    surveyName: Yup.string()
                        //.max(30, "Must be 30 characters or less")
                        .required("Required"),
                    message: Yup.string()
                        // .max(200, "Must be 200 characters or less")
                        .required("Required"),
                    surveyType: Yup.string().required("Required"),
                    options: Yup.array()
                        .of(Yup.string())
                        .test(
                            "validNumberOfOptions",
                            "Between 2 and 10 options are required",
                            function (value) {
                                if (this.parent.surveyType !== "multi") {
                                    return true;
                                }
                                return value.length >= 2 && value.length <= 10;
                            }
                        )
                        .test(
                            "uniqueOptions",
                            "Options must be unique",
                            function (value) {
                                if (this.parent.surveyType !== "multi") {
                                    return true;
                                }
                                const uniqueOptions = new Set(value);
                                return uniqueOptions.size === value.length;
                            }
                        ),
                })}
                onSubmit={(values) => {
                    handleS2FormSave(values);

                    handleNextStep();
                }}
            >
                {({ errors, touched, values, setFieldValue, handleBlur }) => (
                    <Form className="flex flex-row gap-x-9 h-full xl:w-3/4">
                        <div className="flex flex-col p-5 rounded bg-white drop-shadow-md w-full">
                            <Stepper step={step} />
                            {/* templates */}
                            <div className="pb-4">
                                <label
                                    className="text-transparent"
                                    htmlFor="file"
                                >
                                    Select Template
                                </label>
                                <p>{s2Form.surveyName}</p>
                                <div className="flex border-2 border-gray-500 mt-2 rounded-md w-full focus-within:border-primary">
                                    <Field
                                        name="template"
                                        as="select"
                                        className="bg-white border-2 border-transparent m-2 w-full
										focus:outline-none"
                                        onChange={(event) =>
                                            handleTemplate(event, setFieldValue)
                                        }
                                    >
                                        <option value="" disabled hidden>
                                            Choose Template
                                        </option>
                                        {templates.length == 0 && (
                                            <>
                                                <option value="none" disabled>
                                                    No Templates have been
                                                    created yet
                                                </option>
                                            </>
                                        )}
                                        {templates.map((template) => (
                                            <option value={template.id}>
                                                {template.templateName}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                            </div>
                            {/* Survey Form */}
                            <div className="grow flex flex-col gap-x-5 w-full">
                                <div className="flex gap-5 mt-5 w-full flex-col xl:flex-row">
                                    <div className="w-full xl:w-1/2">
                                        <SurveyName
                                            errors={errors}
                                            touched={touched}
                                            Field={Field}
                                        />
                                        <>
                                            <SurveyQuestion
                                                errors={errors}
                                                touched={touched}
                                                Field={Field}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                handlePersonalisation={
                                                    handlePersonalisation
                                                }
                                                personalisation={
                                                    values.personalisation
                                                }
                                                handleMessageTemplate={null}
                                                messageTempalates={null}
                                            />
                                        </>
                                    </div>
                                    <div className="w-full xl:w-1/2">
                                        <SurveyAnswer
                                            errors={errors}
                                            touched={touched}
                                            Field={Field}
                                            FieldArray={FieldArray}
                                            setFieldValue={setFieldValue}
                                            values={values}
                                            handleAnswerTemplate={null}
                                            answerTempalates={null}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="flex gap-x-5 mx-12">
                                    <ButtonFullWidth
                                        type="button"
                                        message="Previous"
                                        action={() => handlePrevStep(values)}
                                        className={`${
                                            step === 1
                                                ? "bg-gray-300 text-gray-500 border-gray-300"
                                                : null
                                        }`}
                                        applyClassName={step === 1}
                                        disabled={step === 1}
                                    />
                                    <ButtonFullWidth
                                        type="submit"
                                        message="Next"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sticky top-5 h-fit">
                            <PhoneMock
                                message={values.message || ""}
                                surveyType={values.surveyType}
                                options={values.options}
                                ratingsArray={values.ratingsArray}
                                noYesArray={values.noYesArray}
                                personalisation={s1Form.personalisation}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
