function NotificationModal() {
    return (
        <ol>
            <li className="list-disc ml-5">
                Trigger: Event in one application (e.g., email received, new
                form submission).
            </li>
            <li className="list-disc ml-5">
                Action: Send an SMS notification to a specified phone number or
                recipient.
            </li>
        </ol>
    );
}

export default NotificationModal;
