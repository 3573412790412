import React, { useState, useEffect, useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AccountContext } from "../context/Account";

const RequireAuth = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(true);
    
	const { getSession } = useContext(AccountContext);

	useEffect(() => {
		getSession()
			.then(() => {
				setIsAuthenticated(true);
				setIsLoading(false);
			})
			.catch(() => {
				setIsAuthenticated(false);
				setIsLoading(false);
			});
	}, []);

	const location = useLocation();

	return isAuthenticated ? (
		<Outlet />
	) : (
		<Navigate to="/login" state={{ from: location }} replace />
	);
};

export default RequireAuth;
