import React from "react";
import ButtonFullWidth from "./ButtonFullWidth";

export default function ChangeApiKey({
    handlePrevStep,
    handleNextStep,
    apiKey,
    apiVendor,
}) {
    const maxLength = 20;
    const truncatedApiKey =
        apiKey.length > maxLength
            ? apiKey.substring(0, maxLength) + "..."
            : apiKey;

    return (
        <>
            <div>
                <h3 className="font-bold">Current API Vendor:</h3>
                <p className="text-xs max-w-md">{apiVendor}</p>
                <h3 className="font-bold">Current API Key:</h3>
                <p className="text-xs">{truncatedApiKey}</p>
            </div>
            <div>
                <div className="flex gap-x-5 mx-12">
                    <ButtonFullWidth
                        type="button"
                        message="Change API Key"
                        action={handleNextStep}
                    />
                </div>
            </div>
        </>
    );
}
