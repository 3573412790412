import { useEffect, useState } from "react";
import DialogModal from "./Dialogmodal";
import Table1 from "./spreadsheetModals/23Survey";
import Table2 from "./spreadsheetModals/IndustryTypeCaseStudies";
import Table3 from "./spreadsheetModals/PainPoints";
import Table4 from "./spreadsheetModals/SMSAndSurveys";
import Table5 from "./spreadsheetModals/SurveyTypeCaseStudies";
import Table from "./Table";

function ModalTable({ isOpened, setIsOpened, csvName }) {
	const [open, setOpen] = useState(false);
	const [name, setName] = useState("");

	useEffect(() => {
		if (isOpened) {
			setOpen(true);
			setIsOpened(false);
		}
	}, [isOpened]);

	useEffect(() => {
		setName(csvName);
		console.log(csvName);
	}, [csvName]);

	let tableComponent;

	if (csvName === "table1") {
		tableComponent = <Table1 />;
		
	} else if (csvName === "table2") {
		tableComponent = <Table2 />;
		
	} else if (csvName === "table3") {
		tableComponent = <Table3 />;
		
	} else if (csvName === "table4") {
		tableComponent = <Table4 />;
		
	} else if (csvName == "table5") {
		tableComponent = <Table5 />;
		
	} else {
		tableComponent = ""
	}

	return (
		<div className="w-1/2">
			<DialogModal
				isOpened={open}
				setOpen={setOpen}
				boxWidth="4/5"
			>
				{tableComponent}
			</DialogModal>
		</div>
	);
}

export default ModalTable;
