import React from "react";
import { Link } from "react-router-dom";

export default function HomeFeatures() {
	return (
		<div className="w-full flex-col">
			<div className="h-32"></div>
			{/* Features */}
			<div className="m-auto flex w-9/12 items-end mb-28">
				<div className="flex flex-col gap-5">
					{/* Expanding automation tool universe */}
					<div className="flex w-2/3 items-center gap-5 rounded bg-white p-5 drop-shadow-md 2xl:w-1/2">
						<img
							className="w-40"
							src="/icons/startup.png"
							alt="Icon of a text bubble coming from icon below, with a smiley and a sad face inside"
						/>
						<div className="grow">
							<h2 className="text-xl font-bold">
								Expanding automation tool universe.
							</h2>
							<p>
								Are you tired of the limited choice of
								efficiency hacks your current provider offer?
								Are you tired of searching for alternatives?
								We find the automation that matters most
								to you.
							</p>
						</div>
					</div>
					{/* Service provider agnostic */}
					<div className="flex w-2/3 items-center gap-5 self-end rounded bg-white p-5 drop-shadow-md 2xl:w-1/2">
						<div className="grow">
							<h2 className="text-xl font-bold">
								Service provider agnostic.
							</h2>
							<p>
								No, you do not need to change from your current
								provider. Just copy a few digits from your
								current provider portal and paste them into API
								shift portal and all our tools will work via
								your current provider instantly.
							</p>
						</div>
						<img
							className="w-40"
							src="/icons/teamwork.png"
							alt="Icon of a text bubble coming from icon below, with a smiley and a sad face inside"
						/>
					</div>
					{/* Easy to use */}
					<div className="flex w-2/3 items-center gap-5 rounded bg-white p-5 drop-shadow-md 2xl:w-1/2">
						<img
							className="w-40"
							src="/icons/time-management.png"
							alt="Icon of a text bubble coming from icon below, with a smiley and a sad face inside"
						/>
						<div className="grow">
							<h2 className="text-xl font-bold">Easy to use.</h2>
							<p>
								Many providers claim ease of use but very few
								deliver. We make your workflows easy and simple. 
							</p>
						</div>
					</div>
					{/* Leverage existing provider */}
					<div className="flex w-2/3 items-center gap-5 self-end rounded bg-white p-5 drop-shadow-md 2xl:w-1/2">
						<div className="grow">
							<h2 className="text-xl font-bold">
								Leverage existing provider.
							</h2>
							<p>
								We do not compete with your provider. Use tools
								that already work but gain additional tools your
								provider does not have.
							</p>
						</div>
						<img
							className="w-40"
							src="/icons/implementation.png"
							alt="Icon of a text bubble coming from icon below, with a smiley and a sad face inside"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
