import React from "react";
import useCsvParser from "../../utils/useCsvParser";

const Table1 = () => {
	const data = useCsvParser("23Surveys.csv");
	// Render your table using the parsed data
	return (
		<div>
            <h1 className="text-2xl pb-5">23 Types of Surveys</h1>
			<table className="text-xs border border-slate-200 rounded-lg overflow-x-auto">
				<thead>
					<tr>
						{data.length > 0 &&
							Object.keys(data[0]).map((key) => (
								<th key={key}>{key}</th>
							))}
					</tr>
				</thead>
				<tbody>
					{data.map((row, index) => (
						<tr key={index}>
							{Object.values(row).map((value, index) => (
								<td key={index}>{value}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
export default Table1;
