import React, { useState } from "react";
import PopUp from "../components/PopUp";
import S1Registration from "../components/registrationForm/S1Registration";
import S2Registration from "../components/registrationForm/S2Registration";
import S3Registration from "../components/registrationForm/S3Registration";
import Stepper from "../components/form/Stepper";
import { Link, useNavigate } from "react-router-dom";

export default function SurveyCreationForm() {
    const [s1Form, sets1Form] = useState({
        vendor: "",
        apiKey: "",
        apiSecret: "",
    });

    const [popUp, setPopUp] = useState(null);
    const [popUpType, setPopUpType] = useState(null);
    const navigate = useNavigate();

    const [step, setStep] = useState(1);

    const handleNextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const handlePrevStep = () => {
        setStep((prevStep) => prevStep - 1);
    };

    return (
        <>
            {popUp && (
                <PopUp
                    popUpType={popUpType}
                    popUp={popUp}
                    onClose={() => setPopUp(null)}
                />
            )}
            <section className="h-screen items-center flex justify-center w-1/2 rounded-lg drop-shadow-md text-gray-700">
                <div className="bg-white rounded-lg py-6 px-12 w-full text-sm">
                    <h1 className="text-3xl text-center">Register</h1>
                    <Stepper step={step} />
                    {step === 1 && (
                        <S1Registration
                            step={step}
                            handlePrevStep={handlePrevStep}
                            handleNextStep={handleNextStep}
                            s1Form={s1Form}
                            sets1Form={sets1Form}
                        />
                    )}
                    {step === 2 && (
                        <S2Registration
                            step={step}
                            handlePrevStep={handlePrevStep}
                            handleNextStep={handleNextStep}
                            s1Form={s1Form}
                        />
                    )}
                    {step === 3 && (
                        <S3Registration
                            step={step}
                            handlePrevStep={handlePrevStep}
                            handleNextStep={handleNextStep}
                            s1Form={s1Form}
                        />
                    )}
                    <div className="text-center text-sm mt-6 w-full">
                        <p>Already registered?</p>
                        <Link to="/login" className="text-primary">
                            Sign In
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}
