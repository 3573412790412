import React, { useState } from "react";
import { Link } from "react-router-dom";
import ButtonFullWidth from "../components/ButtonFullWidth";
import axios from "axios";

export default function HomeContact() {
	const handlePreventClick = (event) => {
		event.preventDefault();
	};

	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handleMessageChange = (e) => {
		setMessage(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// Replace with your Airtable API endpoint and key
		const endpoint =
			"https://api.airtable.com/v0/appgTibpvR1mZn2px/Contacts";

		// Replace with your field names in Airtable
		const data = {
			records: [
				{
					fields: {
						Email: email,
						Message: message,
					},
				},
			],
		};

		axios
			.post(endpoint, data, {
				headers: {
					Authorization: 'Bearer patJVcfBKd2g2Ha6H.c9461e263e1b14dd6eea01e6a5c48b06473b84cf61cb5706aaf6088c5abf64f8',
					"Content-Type": "application/json",
				},
			})
			.then(() => {
				console.log("Data sent successfully!");
				setEmail("");
				setMessage("");
			})
			.catch((error) => {
				console.error("Error sending data:", error);
			});
		console.log(data);
		setEmail("");
		setMessage("");
	};
	return (
		<div className="m-0 w-full flex-col">
			{/* Contact */}
			<div className="m-auto flex h-screen w-9/12 items-end">
				<div className="flex h-5/6 w-full items-center justify-between">
					<div className="flex flex-1 flex-col content-start gap-3 rounded bg-white p-5 drop-shadow-md">
						<div>
							<h1 className="pb-1 text-xl font-bold xl:text-2xl 2xl:text-3xl">
								Contact
							</h1>
							<p className="font-thin text-gray-500">
								Please provide your contact email and message
								for any queries
							</p>
						</div>
						<div className="container py-3">
							<form onSubmit={handleSubmit} className="max-w-md">
								<div className="mb-4">
									<label
										htmlFor="email"
										className="mb-2 block"
									>
										Email:
									</label>
									<input
										type="email"
										id="email"
										className="w-full rounded border px-4 py-2"
										value={email}
										onChange={handleEmailChange}
										required
									/>
								</div>
								<div className="mb-4">
									<label
										htmlFor="message"
										className="mb-2 block"
									>
										Message:
									</label>
									<textarea
										id="message"
										className="w-full rounded border px-4 py-2"
										value={message}
										onChange={handleMessageChange}
										required
									/>
								</div>
								<button
									type="submit"
									className="rounded-full bg-white px-5 py-3 text-start text-primary outline outline-2 outline-primary
								duration-300 ease-in-out hover:bg-primary hover:text-white"
								>
									Submit
								</button>
							</form>
						</div>
					</div>
					<div className="flex h-2/3 flex-1 flex-col items-end justify-center">
						<img
							className="h-full"
							src="/icons/workspace.png"
							alt="Icon of a hand presenting a key"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
