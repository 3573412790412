import React from "react";
import { Link } from "react-router-dom";

export default function HomeSBMGateway() {
	return (
		<div className="w-full flex-col">
		<div className="h-32"></div>
			{/* Use Cases */}
			<div className="m-auto flex w-9/12 flex-col items-end gap-10">
				<div className="flex w-full items-center gap-11 rounded bg-white p-5 drop-shadow-md">
					<div className="flex flex-col gap-10">
						<div className="flex flex-col gap-5">
							<h1 className="pb-3 text-2xl font-bold xl:text-3xl 2xl:text-4xl">
							SBMGateway
							</h1>
							<img className="max-w-sm" src="/jungle-drum-logo.svg"/>
							<p className="pb-1">
							Instantly convert your current mobile phone subscription into a business messaging power tool.

							</p>
							
							<p>No need to sign up for another cloud based service provider.  The power is in your pocket!</p>
						</div>
						<div className="flex items-center w-fit gap-5">
							<div className="flex h-full w-fit flex-col">
								<Link
									to="https://app.jungledrum.com/"
									className="rounded-full bg-white px-5 py-3 text-start text-primary outline outline-2 outline-primary
									duration-300 ease-in-out hover:bg-primary hover:text-white"
								>
									<span className="sr-only">
										Access Register page for survey app
									</span>
									Register here
								</Link>
							</div>
							<div>
								<p className="text-xs">$29.99 pm / 1 user</p>
								<p className="mt-2 text-xs">$69.99 pm / up to 3 three users</p>
								<p className="mt-2 text-xs">More than 3 users contact us for a quote</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
