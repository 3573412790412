import React, { useState, useEffect, useRef } from "react";
import Graph from "./graph";
import Tooltip from "./Tooltip";

export default function SurveyResults({ results }) {
	const searchParams = new URLSearchParams(window.location.search);
	const surveyId = searchParams.get("surveyId");

	const [arrayOptions, setArrayOptions] = useState([]);
	const [arrayCounts, setArrayCounts] = useState([]);

	useEffect(() => {
		if (results.counts) {
			const transformedData = Object.entries(results.counts).map(
				([option, counts]) => ({
					option,
					counts,
				})
			);

			const options = transformedData.map((d) => d.option);
			setArrayOptions(options);

			const count = transformedData.map((d) => d.counts);
			setArrayCounts(count);
		}
	}, [results]);

	if (!results) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			<div className="flex gap-5 mt-10 flex-col xl:flex-row">
				{results.total_responses > 0 ? (
					<>
						<div className="p-5 rounded bg-white drop-shadow-md w-full xl:w-1/2 2xl:w-2/5">
							<h2 className="text-xl pb-2">Responses:</h2>
							<table className="w-full">
								<thead className="offscreen">
									<tr>
										<th>Option</th>
										<th>Count</th>
										<th>Percentage</th>
									</tr>
								</thead>
								<tbody>
									{results.counts &&
										Object.entries(results.counts).map(
											([option, count]) => (
												<tr key={option}>
													<td>{option}:</td>
													<td>
														{count} people chose
														this
													</td>
													<td>
														{results.percentages[
															option
														] || 0}
														% of total responses
													</td>
												</tr>
											)
										)}
								</tbody>
							</table>
						</div>

						<div className="grow flex flex-col h-auto p-5 rounded bg-white drop-shadow-md w-full xl:w-1/2 2xl:w-3/5">
							<p>Total responses:</p>
							<div className="flex items-center gap-1">
								<h2 className="text-xl ml-1">
									{results.total_valid_responses}
								</h2>
								<div className="w-5">
									<Tooltip
										text="Breakdown:"
										total_valid_responses={results.total_valid_responses}
										total_responses={results.total_responses}
										total_sent="TBD"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="w-5 h-5"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
											/>
										</svg>
									</Tooltip>
								</div>
							</div>
							<div className="grow m-3 mr-0">
								<Graph
									arrayOptions={arrayOptions}
									arrayCounts={arrayCounts}
								/>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="h-96 p-5 m-5 rounded bg-white drop-shadow-md">
							<h2 className="text-3xl">Responce Options:</h2>
						</div>
						<div className="grow flex flex-col h-auto p-5 m-5 rounded bg-white drop-shadow-md"></div>
					</>
				)}
			</div>
		</div>
	);
}
