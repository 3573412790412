import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray } from "formik";

import SurveyQuestion from "../components/form/SurveyQuestion";
import SurveyAnswer from "../components/form/SurveyAnswer";
import ButtonFullWidth from "../components/ButtonFullWidth";
import SurveyName from "../components/form/SurveyName";
import PopUp from "../components/PopUp";
import { useNavigate } from "react-router-dom";

export default function Survey() {
	const [templateQuestion, settemplateQuestion] = useState("");
	const [templateName, settemplateName] = useState("");
	const [templateType, settemplateType] = useState("");
	const [surveyName, setsurveyName] = useState("");
	const searchParams = new URLSearchParams(window.location.search);
	const templateId = searchParams.get("surveyTemplateId");
	const [ratingsArray, setRatingsArray] = useState(["", ""]);
	const [options, setOptions] = useState([]);
	const [templates, setTemplates] = useState([]);

	const [displayName, setdisplayName] = useState(false);
	const [displayQuestion, setdisplayQuestion] = useState(false);
	const [displayAnswer, setdisplayAnswer] = useState(false);

	const [popUp, setPopUp] = useState(null);
	const [popUpType, setPopUpType] = useState(null);

	const navigate = useNavigate();

	// Start of patch job
	// Retrieving stored Templates
	useEffect(() => {
		const storedSurveys = JSON.parse(
			sessionStorage.getItem("surveyTemplates")
		);
		if (storedSurveys) {
			setTemplates(storedSurveys);
		}
	}, []);

	//
	useEffect(() => {
		if (templateId) {
			let templateData = [];
			if (templates.length > 0) {
				templateData = templates.filter(
					(item) => item.id === templateId
				);
				templateData = templateData[0];
				settemplateName(templateData.templateName);

				if (templateData.surveyName !== "Null") {
					setdisplayName(true);
					setsurveyName(templateData.surveyName);
				}
				if (templateData.question !== "Null") {
					setdisplayQuestion(true);
					settemplateQuestion(templateData.question);
				}
				if (templateData.type !== "Null") {
					setdisplayAnswer(true);
					settemplateType(templateData.type);

					if (templateData.type === "multi") {
						const array = templateData.answers.split("/%/%/%");
						setOptions(array);
					} else if (templateData.type === "rating") {
						const array = templateData.answers.split("/%/%/%");
						setRatingsArray(array);
					}
				}
			}
		}
	}, [templates]);
	// End of patch job

	// BETTER WAY Get template if already in session storage
	// useEffect(() => {
	// 	if (templateId) {
	// 		const storedSurveys = sessionStorage.getItem(
	// 			`surveyTemplate_${templateId}`
	// 		);
	// 		if (storedSurveys) {
	// 			setSurveyTemplate(JSON.parse(storedSurveys));
	// 		}
	// 	}
	// }, [templateId]);

	// useEffect(() => {
	// 	console.log(templateId);
	// 	if (templateId) {
	// 		// Get content of template
	// 		console.log("get");
	// 		const form_data = {
	// 			template_id: templateId,
	// 		};

	// 		const lambdaEndpoint =
	// 			"https://bjhwme3ygm2zppnn5llfy2eysq0koafn.lambda-url.ap-southeast-2.on.aws/";

	// 		fetch(lambdaEndpoint, {
	// 			method: "POST",
	// 			headers: { "Content-Type": "application/json" },
	// 			body: JSON.stringify(form_data),
	// 		})
	// 			.then((response) => response.json())
	// 			.then((surveyTemplate) => {
	// 				setSurveyTemplate(surveyTemplate);
	// 				sessionStorage.setItem(
	// 					`surveyTemplate_${templateId}`,
	// 					JSON.stringify(surveyTemplate)
	// 				);
	// 			})
	// 			.catch((error) => console.error(error));
	// 	}
	// }, [templateId]);

	// useEffect(() => {
	// 	const surveys = JSON.parse(sessionStorage.getItem("surveys"));
	// 	if (surveys) {
	// 		const transformedData = surveys.map((template) => ({ template }));
	// 		const template = surveys.find((obj) => obj.id === templateId);
	// 		setSurvey(template);
	// 	} else {
	// 		setSurvey(surveyTemplate);
	// 		console.log(surveyTemplate);
	// 	}
	// }, [templateId, surveyTemplate]);

	// let optionDisplay = "";

	// Remove a Survey Template by ID
	// function update(templateId) {}

	// useEffect(() => {
	// 	if (surveyTemplate.templateType === "rating") {
	// 		const array = surveyTemplate.templateData.split("/%/%/%");
	// 		setRatingsArray(array);
	// 	} else {
	// 		if (surveyTemplate.templateType === "multi") {
	// 			const array = surveyTemplate.templateData.split("/%/%/%");
	// 			setOptions(array);
	// 		}
	// 	}
	// }, [surveyTemplate]);
	
	if (!templates) {
		return <div>Loading...</div>;
	}

	return (
		<>
			{popUp && (
				<PopUp
					popUpType={popUpType}
					popUp={popUp}
					onClose={() => setPopUp(null)}
				/>
			)}
			<div className="">
				<div className="flex w-full gap-5 mb-5 justify-between">
					<div className="p-5 rounded bg-white drop-shadow-md w-full">
						<h1 className="text-3xl">{templateName}</h1>
					</div>
				</div>
				<Formik
					enableReinitialize
					initialValues={{
						displayName: displayName,
						displayQuestion: displayQuestion,
						displayAnswer: displayAnswer,
						surveyName: surveyName,
						message: templateQuestion,
						surveyType: templateType,
						ratingsArray: ratingsArray,
						noYesArray: ["no", "yes"],
						options: options,
						newOptions: "",
					}}
					onSubmit={(values) => {
						let templateData = [];

						if (!values.displayName) {
							values.surveyName = "";
						}

						if (!values.displayQuestion) {
							values.message = "";
						}

						if (!values.displayAnswer) {
							templateData = "";
						} else if (values.surveyType === "multi") {
							templateData = values.options;
						} else if (values.surveyType === "rating") {
							templateData = values.ratingsArray;
						} else {
							templateData = values.noYesArray;
						}
						const formData = {
							template_id: templateId,
							// survey_name: values.name,
							template_question: values.message,
							template_type: values.surveyType,
							template_answers: templateData,
						};

						const lambdaEndpoint =
							"https://gha46tcnhu3sirq7va3aep2phi0belrc.lambda-url.ap-southeast-2.on.aws/";

						// send the form data to the Lambda function
						fetch(lambdaEndpoint, {
							method: "POST",
							body: JSON.stringify(formData),
							headers: {
								"Content-Type": "application/json",
								//"Access-Control-Allow-Origin":
								//	"https://virtualdev.com",
								"Access-Control-Allow-Headers": "Content-Type",
							},
						})
							.then((response) => {
								if (response.ok) {
									setPopUpType("success");
									setPopUp("Template successfully saved");
									navigate("/surveyTemplateDashboard");
								} else {
									setPopUpType("error");
									setPopUp(
										"An error occurred. Please try again later."
									);
								}
							})
							.catch((error) => console.error(error));
					}}
				>
					{({
						errors,
						touched,
						values,
						setFieldValue,
						handleBlur,
					}) => (
						<Form className="flex flex-row gap-x-9 h-10/12 w-full">
							<div className="flex flex-col p-5 rounded bg-white drop-shadow-md w-full">
								<div className="grow flex flex-col gap-x-5 w-full">
									<div className="flex flex-col flex-1 justify-start">
										{/* Check boxes */}
										<h2>
											Included Survey Sections in
											Template:
										</h2>
										<div className="flex flex-col ml-4">
											<label className="flex items-center">
												<Field
													type="checkbox"
													name="displayName"
													className="form-checkbox w-3 h-3 text-green-600 border-0 rounded-md focus:ring-0"
												/>
												<span className="ml-2">
													Survey Name
												</span>
											</label>
											<label className="flex items-center">
												<Field
													type="checkbox"
													name="displayQuestion"
													className="w-3 h-3 bg-green-600 border-0 rounded-md focus:ring-0"
												/>
												<span className="ml-2">
													Survey Question
												</span>
											</label>
											<label className="flex items-center">
												<Field
													type="checkbox"
													name="displayAnswer"
													className="w-3 h-3 bg-green-600 border-0 rounded-md focus:ring-0"
												/>
												<span className="ml-2">
													Survey Answers
												</span>
											</label>
											{errors.atLeastOneCheckbox &&
												touched.atLeastOneCheckbox && (
													<div className="text-red-500">
														{
															errors.atLeastOneCheckbox
														}
													</div>
												)}
										</div>
										{/* Survey */}
										<div className="flex gap-5 mt-5 w-full flex-col xl:flex-row">
											<div className="w-full xl:w-1/2">
												{values.displayName && (
													<SurveyName
														errors={errors}
														touched={touched}
														Field={Field}
													/>
												)}
												{values.displayQuestion ? (
													<>
														<SurveyQuestion
															errors={errors}
															touched={touched}
															Field={Field}
															setFieldValue={
																setFieldValue
															}
															values={values}
															handlePersonalisation={
																null
															}
															personalisation={
																null
															}
															handleMessageTemplate={
																null
															}
															messageTempalates={
																null
															}
														/>
													</>
												) : null}
											</div>
											<div className="w-full xl:w-1/2">
												{values.displayAnswer ? (
													<SurveyAnswer
														errors={errors}
														touched={touched}
														Field={Field}
														FieldArray={FieldArray}
														setFieldValue={
															setFieldValue
														}
														values={values}
														handleAnswerTemplate={
															null
														}
														answerTempalates={null}
													/>
												) : null}
											</div>
										</div>
									</div>
								</div>
								<ButtonFullWidth
									type="submit"
									message="Save"
								/>
							</div>
							{/* <div className="w-72"></div> */}
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
}
