import React, { useState, useContext, useEffect } from "react";
import PopUp from "../components/PopUp";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { AccountContext } from "../context/Account";
import * as AWS from "aws-sdk";
import PhoneMock from "../components/PhoneMock";
import SurveyQuestion from "../components/form/SurveyQuestion";
import SurveyAnswer from "../components/form/SurveyAnswer";
import ButtonFullWidth from "../components/ButtonFullWidth";
import SurveyName from "../components/form/SurveyName";

//import { CognitoIdentityServiceProvider } from "aws-sdk";

export default function SurveyTemplateCreationForm() {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [u_id, set_u_id] = useState("");

	const { getSession } = useContext(AccountContext);

	const [popUp, setPopUp] = useState(null);
	const [popUpType, setPopUpType] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		getSession().then((session) => {
			const token = session.accessToken.jwtToken.toString();

			const user = session.accessToken.payload.username;
			set_u_id(user);

			const cognitoidentityserviceprovider =
				new AWS.CognitoIdentityServiceProvider({
					region: "ap-southeast-2",
					apiVersion: "2016-04-18",
				});

			var params = {
				AccessToken: token,
			};
		});
	}, []);

	return (
		<>
			{popUp && (
				<PopUp
					popUpType={popUpType}
					popUp={popUp}
					onClose={() => setPopUp(null)}
				/>
			)}
			<Formik
				initialValues={{
					displayName: false,
					displayQuestion: false,
					displayAnswer: false,
					templateName: "",
					surveyName: "",
					message: "",
					surveyType: "",
					ratingsArray: ["", ""],
					noYesArray: ["no", "yes"],
					options: [],
					newOptions: "",
					tab: "",
				}}
				validationSchema={Yup.object({
					templateName: Yup.string()
						//.max(30, "Must be 30 characters or less")
						.required("Required"),
					options: Yup.lazy((values) => {
						if (values.surveyType === "multi") {
							return Yup.array()
								.min(2, "Create at least two options")
								.of(
									Yup.string().required(
										"Option cannot be empty"
									)
								)
								.required("Required");
						} else {
							return Yup.mixed().notRequired();
						}
					}),
					// atLeastOneCheckbox: Yup.boolean()
					// 	.oneOf([true], "Please select at least one checkbox")
					// 	.required(),
				})}
				onSubmit={(values) => {
					setIsSubmitting(true)
					let templateData = [];

					if (!values.displayName) {
						values.surveyName = "";
					}

					if (!values.displayQuestion) {
						values.message = "";
					}

					if (!values.displayAnswer) {
						templateData = "";
					} else if (values.surveyType === "multi") {
						templateData = values.options;
					} else if (values.surveyType === "rating") {
						templateData = values.ratingsArray;
					} else {
						templateData = values.noYesArray;
					}

					const formData = {
						template_name: values.templateName,
						survey_name: values.surveyName,
						template_question: values.message,
						template_type: values.surveyType,
						template_answers: templateData,
						u_id: u_id,
					};

					const lambdaEndpoint =
						"https://boxx7invxzbjcaj2wrpkqajg3e0zqawj.lambda-url.ap-southeast-2.on.aws/";

					// send the form data to the Lambda function
					fetch(lambdaEndpoint, {
						method: "POST",
						body: JSON.stringify(formData),
						headers: {
							"Content-Type": "application/json",
							//"Access-Control-Allow-Origin":
							//	"https://virtualdev.com",
							"Access-Control-Allow-Headers": "Content-Type",
						},
					})
						.then((response) => {
							if (response.ok) {
								navigate("/surveyTemplateDashboard");
							} else {
								setPopUpType("error");
								setPopUp(
									"An error occurred. Please try again later."
								);
								setIsSubmitting(false);
							}
						})
						.catch((error) => {
							console.error(error);
							setIsSubmitting(false);
						});
				}}
			>
				{({ errors, touched, values, setFieldValue, handleBlur }) => (
					<Form className="flex flex-row gap-x-9 h-full w-10/12">
						<div className="flex flex-col p-5 rounded bg-white drop-shadow-md w-full">
							<div className="grow flex flex-col gap-x-5 w-full">
								<div className="flex flex-col flex-1 justify-start">
									{/* templateName */}
									<div className="pb-4">
										{errors.templateName &&
										touched.templateName ? (
											<div className="text-red-400">
												{errors.templateName}
											</div>
										) : (
											<label
												htmlFor="templateName"
												className=""
											>
												Template Name:
											</label>
										)}
										<Field
											name="templateName"
											placeholder="Enter the Template Name"
											className="border-2 border-gray-500 p-2 mt-2 rounded-md w-full 
											focus:outline-none focus:border-primary"
										/>
									</div>
									{/* Check boxes */}
									<h2>
										Included Survey Sections in Template:
									</h2>
									<div className="flex flex-col ml-4">
										<label className="flex items-center">
											<Field
												type="checkbox"
												name="displayName"
												className="form-checkbox w-3 h-3 text-green-600 border-0 rounded-md focus:ring-0"
											/>
											<span className="ml-2">
												Survey Name
											</span>
										</label>
										<label className="flex items-center">
											<Field
												type="checkbox"
												name="displayQuestion"
												className="w-3 h-3 bg-green-600 border-0 rounded-md focus:ring-0"
											/>
											<span className="ml-2">
												Survey Question
											</span>
										</label>
										<label className="flex items-center">
											<Field
												type="checkbox"
												name="displayAnswer"
												className="w-3 h-3 bg-green-600 border-0 rounded-md focus:ring-0"
											/>
											<span className="ml-2">
												Survey Answers
											</span>
										</label>
										{errors.atLeastOneCheckbox &&
											touched.atLeastOneCheckbox && (
												<div className="text-red-500">
													{errors.atLeastOneCheckbox}
												</div>
											)}
									</div>
									{/* Survey */}
									<div className="flex gap-5 mt-5 w-full flex-col xl:flex-row">
										<div className="w-full xl:w-1/2">
											{values.displayName && (
												<SurveyName
													errors={errors}
													touched={touched}
													Field={Field}
												/>
											)}
											{values.displayQuestion ? (
												<>
													<SurveyQuestion
														errors={errors}
														touched={touched}
														Field={Field}
														setFieldValue={
															setFieldValue
														}
														values={values}
														handlePersonalisation={
															null
														}
														personalisation={null}
														handleMessageTemplate={
															null
														}
														messageTempalates={null}
													/>
												</>
											) : null}
										</div>
										<div className="w-full xl:w-1/2">
											{values.displayAnswer ? (
												<SurveyAnswer
													errors={errors}
													touched={touched}
													Field={Field}
													FieldArray={FieldArray}
													setFieldValue={
														setFieldValue
													}
													values={values}
													handleAnswerTemplate={null}
													answerTempalates={null}
												/>
											) : null}
										</div>
									</div>
								</div>
							</div>
							<ButtonFullWidth
								type="submit"
								message="Submit"
								disabled={isSubmitting}
								loading={true}
							/>
						</div>
						<div className="sticky top-5 h-fit">
							<PhoneMock
								message={values.message}
								surveyType={values.surveyType}
								options={values.options}
								ratingsArray={values.ratingsArray}
								noYesArray={values.noYesArray}
								notDisplayQuestion={!values.displayQuestion}
								notDisplayAnswer={!values.displayAnswer}
								fromTemplates={true}
							/>
						</div>
						{/* <div className="w-72"></div> */}
					</Form>
				)}
			</Formik>
		</>
	);
}
