import React from "react";
import { Link, useLocation } from "react-router-dom";
import ButtonSubNav from "./ButtonSubNav";

export default function SubNav() {
    const location = useLocation();
    return (
        <nav>
            <ul className="flex">
                <li className="mb-2">
                    <ButtonSubNav
                        isLocation={location}
                        link="/surveyTemplateDashboard"
                        message="Dashboard"
                    />
                </li>
                <li className="mb-2">
                    <ButtonSubNav
                        isLocation={location}
                        link="/surveyTemplateDashboardExample"
                        message="Examples"
                    />
                </li>
            </ul>
        </nav>
    );
}
