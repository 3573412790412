import React from "react";
import useCsvParser from "../../utils/useCsvParser";

const Table4 = () => {
	const data = useCsvParser("SMSAndSurveys.csv");
	// Render your table using the parsed data
	return (
		<div>
			<h1 className="text-2xl">SMS and Surveys</h1>
			<p className="text pb-5">
				Several survey types can yield immediate high-impact results
				using SMS (Short Message Service) as the communication channel.
				SMS surveys are particularly effective for reaching a wide range
				of audiences quickly and conveniently. Here are some survey
				types that work well with SMS:
			</p>
			<table className="text-xs border border-slate-200 rounded-lg overflow-x-auto">
				<thead>
					<tr>
						{data.length > 0 &&
							Object.keys(data[0]).map((key) => (
								<th key={key}>{key}</th>
							))}
					</tr>
				</thead>
				<tbody>
					{data.map((row, index) => (
						<tr key={index}>
							{Object.values(row).map((value, index) => (
								<td key={index}>{value}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			<p className="pt-5">
				By leveraging SMS surveys effectively, organizations can reach a
				broader audience, collect valuable feedback, and make
				data-driven decisions to improve their products, services, and
				customer experiences.
			</p>
		</div>
	);
};
export default Table4;
