import { Link } from "react-router-dom";

const Missing = () => {
	return (
		<div className="flex flex-col items-center m-auto">
			<h1 className="text-5xl">Oops!</h1>
			<p>Page Not Found</p>
			<Link to="/">Visit Our Homepage</Link>
		</div>
	);
};

export default Missing;
