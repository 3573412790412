import React from "react";

export default function SurveyQuestion({
	errors,
	touched,
	Field,
	setFieldValue,
	values,
	handlePersonalisation,
	personalisation,
	handleMessageTemplate,
	messageTempalates,
}) {
	return (
		<>
			{/* message */}
			<div className="pb-4">
				<div className="flex justify-between">
					{errors.message && touched.message ? (
						<div className="text-red-400">{errors.message}</div>
					) : (
						<>
							<label htmlFor="message">Message:</label>
						</>
					)}
					{personalisation && (
						<div className="flex border-2 border-gray-500 rounded-md focus-within:border-primary">
							<Field
								name="personalisation"
								as="select"
								className="bg-white mx-1 text-xs
													focus:outline-none focus:border-primary"
								value=""
								onChange={(event) =>
									handlePersonalisation(
										event,
										setFieldValue,
										values
									)
								}
							>
								<option value="" disabled hidden>
									Add Personalisation
								</option>
								{personalisation.length > 0 ? (
									personalisation[0].map((header) => (
										<option value={header}>{header}</option>
									))
								) : (
									<option disabled>
										None available: Insert CSV
									</option>
								)}
							</Field>
						</div>
					)}
					
				</div>
				<Field
					name="message"
					as="textarea"
					placeholder="Enter the Message"
					className="border-2 border-gray-500 p-2 mt-2 rounded-md w-full
					focus:outline-none focus:border-primary"
				/>
			</div>
		</>
	);
}
