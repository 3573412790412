import React, { useState, useEffect } from "react";
import SurveyResults from "../components/surveyResults";

export default function Survey() {
	const [isLoading, setIsLoading] = useState(true);

	const [survey, setSurvey] = useState("");
	const [question, setQuestion] = useState([]);
	const [surveyQuestion, setSurveyQuestion] = useState([]);
	const [results, setResults] = useState([]);

	const searchParams = new URLSearchParams(window.location.search);
	const surveyId = searchParams.get("surveyId");

	useEffect(() => {
		if (surveyId) {
			const storedSurveys = sessionStorage.getItem(
				`surveyResults_${surveyId}`
			);
			if (storedSurveys) {
				setResults(JSON.parse(storedSurveys));
			}
		}
	}, [surveyId]);

	useEffect(() => {
		if (surveyId) {
			const request1 = fetch(
				// Get response totals
				"https://sa8usp6ky6.execute-api.ap-southeast-2.amazonaws.com/default/getMessagesBySurveyId",
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ surveyId }),
				}
			)
				.then((response) => response.json())
				.then((results) => {
					setResults(results);
					sessionStorage.setItem(
						`surveyResults_${surveyId}`,
						JSON.stringify(results)
					);
				})

			// Get content of survey / question
			const request2 = fetch(
				"https://31by4v6om1.execute-api.ap-southeast-2.amazonaws.com/default/getSurveyDetailsBySurveyId",
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ surveyId }),
				}
			)
				.then((response) => response.json())
				.then((surveyQuestion) => {
					setSurveyQuestion(surveyQuestion);
					sessionStorage.setItem(
						`surveyQuestion_${surveyId}`,
						JSON.stringify(surveyQuestion)
					);
				})

			const fetchData = async () => {
				try {
					const responses = await Promise.all([request1, request2]);
					setIsLoading(false);
				} catch (error) {
					console.error("Error: ", error);
					setIsLoading(false);
				}
			};
			fetchData();
		}
	}, [surveyId]);
	/*
	// Get u_id
	useEffect(() => {
		getSession().then((session) => {
			const user = session.accessToken.payload.username;
			set_u_id(user);
		});
	}, []);
	*/
	useEffect(() => {
		const surveys = JSON.parse(
			sessionStorage.getItem(`surveyQuestion_${surveyId}`)
		);
		if (surveys) {
			setSurvey(surveys);
		} else {
			setSurvey(surveyQuestion);
		}
	}, [surveyId, surveyQuestion]);

	// setQuestion
	useEffect(() => {
		if (surveyQuestion.length > 0) {
			setQuestion(surveyQuestion.details.question);
		}
	}, [surveyQuestion, results]);

	let optionDisplay = "";

	switch (results.survey_type) {
		case "multi":
			optionDisplay = results.survey_options.map((result, index) => (
				<p key={index}>{result}</p>
			));
			break;
		case "rating":
			optionDisplay = `From ${results.survey_options[0]} to ${results.survey_options[1]}`;
			break;
		case "noYes":
			optionDisplay = `${results.survey_options[1]} or ${results.survey_options[0]}`;
			break;
		default:
			optionDisplay = "";
	}

	// if (!surveyQuestion || !results.survey_options) {
	// 	return (
	// 		<div className="w-full h-full items-center flex justify-center">
	// 			<p>Loading...</p>
	// 		</div>
	// 	);
	// }

	if (isLoading) {
		return (
			<div className="w-full h-full items-center flex justify-center">
				<p>Loading...</p>
			</div>
		);
	}

	if (!isLoading && !results.survey_options) {
		return (
			<div className="w-full h-full items-center flex justify-center">
				<p>No results found</p>
			</div>
		);
	}

	return (
		<div className="mx-5">
			<div className="flex w-full gap-5 justify-between md:flex-col xl:flex-row">
				{/* Name */}
				<div className="p-5 rounded bg-white drop-shadow-md xl:w-2/5">
					<h1 className="text-3xl">{survey.name}</h1>
				</div>
				{/* Question and Answer */}
				<div className="flex gap-5 xl:w-3/5">
					<div className="p-5 rounded bg-white drop-shadow-md basis-8/12">
						<h3>Question</h3>
						<p className="ml-6">{survey.details.question}</p>
						<p></p>
					</div>
					<div className="p-5 rounded bg-white drop-shadow-md basis-4/12">
						<h3>Options</h3>
						<div className="ml-6">{optionDisplay}</div>
					</div>
				</div>
			</div>
			<SurveyResults results={results} />
		</div>
	);
}
