import React from "react";
import { Link, useLocation } from "react-router-dom";
import ButtonNav from "./ButtonNav";

export default function Sidebar() {
    const location = useLocation();

    return (
        <div className="flex flex-col fixed top-0 left-0 h-screen w-1/6 overflow-y-auto bg-primary text-slate-100 md:p-4 xl:p-8">
            <div className="flex justify-center mb-8">
                <Link to="/" className="nav-link">
                    <h1 className="font-Economica text-white md:text-3xl xl:text-4xl 2xl:text-5xl">
                        SwiftSurvey
                    </h1>
                </Link>
            </div>
            {/* <div className=""> */}
            <nav className="flex grow w-full md:text-xs 2xl:text-sm">
                <ul className="flex flex-col justify-between grow h-full">
                    <div>
                        <li className="mb-2">
                            <ButtonNav
                                isLocation={location}
                                link="/dashboard"
                                message="Dashboard"
                                classActiveFor="/dashboard"
                            />
                        </li>
                        <li className="mb-2">
                            <ButtonNav
                                isLocation={location}
                                link="/surveyCreationForm"
                                message="Survey Creation"
                                classActiveFor="/surveyCreationForm"
                            />
                        </li>
                        <li className="mb-2">
                            <ButtonNav
                                isLocation={location}
                                link="/surveyTemplateDashboard"
                                message="Templates"
                                classActiveFor={["/surveyTemplateDashboard", "/surveyTemplateDashboardExample"]}
                            />
                        </li>
                        <li className="mb-2">
                            <ButtonNav
                                isLocation={location}
                                link="/surveyTemplateCreationForm"
                                message="Template Creation"
                                classActiveFor="/surveyTemplateCreationForm"
                            />
                        </li>
                        <li className="mb-2">
                            <ButtonNav
                                isLocation={location}
                                link="/aboutswiftsurvey"
                                message="About"
                                classActiveFor="/aboutswiftsurvey"
                            />
                        </li>
                    </div>
                    <li className="mb-2 flex justify-center">
                        <ButtonNav
                            isLocation={location}
                            link="/setting"
                            message="Setting"
                            linkClassName="text-center"
                        />
                    </li>
                </ul>
            </nav>
        </div>
    );
}
