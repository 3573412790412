import React, { useState } from "react";
import { Link } from "react-router-dom";
import InfoModal from "../components/InfoModal";

export default function HomeWarehouse() {
    const [isOpened, setIsOpened] = useState(false);
    const [link, setLink] = useState("");

    const handleModalOpen = (name) => {
        setIsOpened(true);
        setLink(name);
    };

    return (
        <>
            <InfoModal
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                link={link}
            />

            <div className="w-full flex-col">
                <div className="h-32"></div>
                {/* Use Cases */}
                <div className="m-auto flex w-9/12 flex-col gap-10 rounded bg-white p-5 drop-shadow-md">
                    <h1 className="text-2xl font-bold xl:text-3xl 2xl:text-4xl">
                        In stock now:
                    </h1>
                    <div className="flex items-center gap-11 mb-10">
                        <div className="flex flex-col content-start gap-10">
                            <div className="flex h-full flex-col gap-5">
                                <div className="flex items-center gap-5">
                                    <Link
                                        to="/warehouse/swiftsurvey"
                                        className="w-36 rounded-full bg-white px-5 py-3 text-center text-primary outline outline-2 outline-primary
												duration-300 ease-in-out hover:bg-primary hover:text-white"
                                    >
                                        <span className="sr-only">
                                            Access Register page for survey app
                                        </span>
                                        SwiftSurvey
                                    </Link>
                                    <p>Easy to use survey templates</p>
                                </div>
                                <div className="flex items-center gap-5">
                                    <Link
                                        to="/warehouse/sbmgateway"
                                        className="w-36 rounded-full bg-white px-5 py-3 text-center text-primary outline outline-2 outline-primary
												duration-300 ease-in-out hover:bg-primary hover:text-white"
                                    >
                                        <span className="sr-only">
                                            Access Register page for survey app
                                        </span>
                                        SBMGateway
                                    </Link>
                                    <p className="w-1/2">
                                        Use your current mobile phone for free
                                        messaging - no other service required
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h1 className="text-2xl font-bold xl:text-3xl 2xl:text-4xl">
                        Coming soon:
                    </h1>
                    <div className="flex flex-col content-start gap-10">
                        <div className="grid grid-cols-2 lg:grid-cols-3 w-full gap-4 mx-auto">
                            <button
                                type="button"
                                className="rounded-full bg-white px-5 py-3 text-center text-gray-500 outline outline-2 outline-gray-500 
									duration-300 ease-in-out hover:bg-gray-500 hover:text-white"
                                onClick={() => handleModalOpen("Link1")}
                            >
                                SMS Notifications:
                            </button>
                            <button
                                type="button"
                                className="rounded-full bg-white px-5 py-3 text-center text-gray-500 outline outline-2 outline-gray-500 
									duration-300 ease-in-out hover:bg-gray-500 hover:text-white"
                                onClick={() => handleModalOpen("Link2")}
                            >
                                Marketing
                            </button>
                            <button
                                type="button"
                                className="rounded-full bg-white px-5 py-3 text-center text-gray-500 outline outline-2 outline-gray-500 
									duration-300 ease-in-out hover:bg-gray-500 hover:text-white"
                                onClick={() => handleModalOpen("Link3")}
                            >
                                Appointment reminder
                            </button>
                            <button
                                type="button"
                                className="rounded-full bg-white px-5 py-3 text-center text-gray-500 outline outline-2 outline-gray-500 
									duration-300 ease-in-out hover:bg-gray-500 hover:text-white"
                                onClick={() => handleModalOpen("Link4")}
                            >
                                Lead generation
                            </button>
                            <button
                                type="button"
                                className="rounded-full bg-white px-5 py-3 text-center text-gray-500 outline outline-2 outline-gray-500 
									duration-300 ease-in-out hover:bg-gray-500 hover:text-white"
                                onClick={() => handleModalOpen("Link5")}
                            >
                                Customer support
                            </button>
                            <button
                                type="button"
                                className="rounded-full bg-white px-5 py-3 text-center text-gray-500 outline outline-2 outline-gray-500 
									duration-300 ease-in-out hover:bg-gray-500 hover:text-white"
                                onClick={() => handleModalOpen("Link6")}
                            >
                                Active directory
                            </button>
                            <button
                                type="button"
                                className="rounded-full bg-white px-5 py-3 text-center text-gray-500 outline outline-2 outline-gray-500 
									duration-300 ease-in-out hover:bg-gray-500 hover:text-white"
                                onClick={() => handleModalOpen("Link7")}
                            >
                                Internal Comms
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
