import React, {useEffect} from "react";

export default function PhoneMock(props) {
	const {
		message,
		surveyType,
		options,
		ratingsArray,
		noYesArray,
		notDisplayQuestion,
		notDisplayAnswer,
		fromTemplates,
		personalisation,
	} = props;
	const messageT = message || "";
	
	const highlightedMessage = messageT.split(" ").map((word, index) => {
		if (!personalisation) {
			return null;
		} else {
			if (
				word.startsWith("#") &&
				word.endsWith("#") &&
				personalisation[0].includes(word.slice(1, -1))
				) {
					return (
						<span>
						<span key={index} className="text-primary">
							{word}
						</span>{" "}
					</span>
				);
			}
			return <span key={index}>{word} </span>;
		}
	});
	
	return (
		<div className="w-60 h-[24rem] xl:w-72 xl:h-[31rem] bg-slate-300 border border-slate-300 rounded-xl mx-auto drop-shadow-md">
			<div className="w-4/5 h-4/5 m-auto mt-10 bg-white rounded-lg overflow-auto">
				<div className="m-2 py-2 bg-slate-200 w-2/3 rounded-lg">
					{!notDisplayQuestion && (
						<p className="px-4 pb-0 break-words text-[12px]">
							{highlightedMessage[0] != null ? highlightedMessage : message}
						</p>
					)}
					{!notDisplayAnswer && (
						<div className="px-4 pb-0 break-words text-[12px]">
							{(() => {
								switch (surveyType) {
									case "multi":
										return (
											<>
												<br />
												{options.length > 0 &&
													options.map(
														(option, index) => (
															<div
																key={index}
																className="break-words"
															>
																{String.fromCharCode(
																	65 + index
																)}
																.&#41; {option}
															</div>
														)
													)}
											</>
										);
									case "rating":
										return (
											<>
												<br />
												{ratingsArray.length > 0 && (
													<>
														Reply with a whole
														number between:
														<br />
														{
															ratingsArray[0]
														} and {ratingsArray[1]}
													</>
												)}
											</>
										);
									case "noYes":
										return (
											<>
												<br />y for Yes or n for No
											</>
										);
									default:
										return null;
								}
							})()}
						</div>
					)}
					{surveyType ? (
						<>
							<br />
							<p className="px-4 text-[12px]">
								Reply STOP to opt out.
							</p>
						</>
					) : null}
				</div>
			</div>
		</div>
	);
}
