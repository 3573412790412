import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./UserPool";

export const AccountContext = createContext();

export const Account = (props) => {
	// Get current user
	const getSession = async () => {
		return await new Promise((resolve, reject) => {
			const user = Pool.getCurrentUser();
			if (user) {
				user.getSession((err, session) => {
					if (err) {
						reject();
					} else {
						resolve(session);
					}
				});
			} else {
				reject();
			}
		});
	};

	// Authenticate user
	const authenticate = async (Username, Password) => {
		return await new Promise((resolve, reject) => {
			let user = new CognitoUser({ Username, Pool });
			let authDetails = new AuthenticationDetails({
				Username,
				Password,
			});

			user.authenticateUser(authDetails, {
				onSuccess: (data) => {
					//console.log("onSuccess: ", data);
					resolve(data);
				},
				onFailure: (err) => {
					//console.error("onFailure: ", err);
					reject(err);
				},
				newPasswordRequired: (data) => {
					//console.log("newPasswordRequired: ", data);
					resolve(data);
				},
			});
		});
	};

	const logout = () => {
		const user = Pool.getCurrentUser();
		if (user) {
			user.signOut();
		}
	};

	return (
		<AccountContext.Provider value={{ authenticate, getSession, logout }}>
			{props.children}
		</AccountContext.Provider>
	);
};
