import { useLocation, Outlet } from "react-router-dom";
import { Account } from "../context/Account";
import Shell from "./Shell";
import Sidebar from "./sidebar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';




const LayoutHome = () => {
	const location = useLocation();

	// Define an array of paths where the sidebar should not be rendered
	const sidebarPaths = ["/dashboard", "/survey", "/surveyCreationForm", "/setting", "/surveyTemplateCreationForm", "/surveyTemplate", "/surveyTemplateDashboard"];
	const noCenterPaths = ["/survey", "/setting"];

	// Check if the current page is in the no-sidebar paths array
	const isSidebarPage = sidebarPaths.includes(location.pathname);
	const isNoCenterPage = noCenterPaths.includes(location.pathname);

	return (
		<>
			<Account>
				<div className="flex flex-col">
					<nav className="fixed top-0 z-10 h-24 w-full bg-primary p-6">
						<div className="m-auto h-full max-w-6xl px-4">
							<div className="flex h-full items-center justify-between">
								<div className="">
									<h1 className="font-Economica text-5xl text-white">
										API Shift
									</h1>
								</div>
								<div className="hidden md:block">
									<nav className="ml-10 flex items-baseline space-x-4">
									<Link
											to="/"
											className={`border-b-2 border-transparent px-2 py-1 text-white duration-300 ease-in-out hover:border-white outline-none
											
												${
													location.pathname === "/"
														? "border-white focus:border-white"
														: "focus:border-none"
												}`}
										>
											Home
										</Link>
										<Link
											to="/features"
											className={`border-b-2 border-transparent px-2 py-1 text-white duration-300 ease-in-out hover:border-white outline-none
											
												${
													location.pathname === "/features"
														? "border-white focus:border-white"
														: "focus:border-none"
												}`}
										>
											Features
										</Link>
										<Link
											to="/warehouse"
											className={`border-b-2 border-transparent px-2 py-1 text-white duration-300 ease-in-out hover:border-white outline-none
											
												${
													location.pathname === "/warehouse"
														? "border-white focus:border-white"
														: "focus:border-none"
												}`}
										>
											Warehouse
										</Link>
										<Link
											to="/how"
											className={` border-b-2 border-transparent px-2 py-1 text-white hover:border-white duration-300 ease-in-out outline-none
											 
											${
												location.pathname === '/how'
												? "focus:border-white border-white"
												: "focus:border-none"
											}`}
										>
											How
										</Link>
										<Link
											to="/contact"
											className={` border-b-2 border-transparent px-2 py-1 text-white hover:border-white duration-300 ease-in-out outline-none
											 
											${
												location.pathname === '/contact'
												? "focus:border-white border-white"
												: "focus:border-none"
											}`}
										>
											Contact
										</Link>
										<Link
										to="/login"
										className="rounded-full bg-white px-5 py-3 text-start text-primary outline outline-2 outline-primary
									duration-300 ease-in-out hover:bg-primary hover:text-white"
									>
										<span className="sr-only">
											Access Register page for survey app
										</span>
										Login
									</Link>
									</nav>
								</div>
							</div>
						</div>
					</nav>
					<main
						className={`${isNoCenterPage ? "" : "items-center"} ${
							isSidebarPage ? "w-5/6 py-5" : "w-full"
						}`}
					>
						<Outlet />
					</main>
					<footer className="flex items-center w-full justify-between p-8 border-t-2 border-gray-300 mt-10">
						<div className="flex items-center">
							<h1 className="font-Economica text-2xl">
								API Shift
							</h1>
							<div className="flex items-center ml-10 text-xs">
								<FontAwesomeIcon icon={faCopyright} style={{ marginRight: '5px' }} />
								<p>API Shift</p>
							</div>
						</div>
						<div className="flex text-2xl gap-5">
							<FontAwesomeIcon icon={faTwitter} style={{ marginRight: '5px' }} />
							<FontAwesomeIcon icon={faInstagram} style={{ marginRight: '5px' }} />
							<FontAwesomeIcon icon={faFacebook} style={{ marginRight: '5px' }} />
						</div>
					</footer>
				</div>
			</Account>
		</>
	);
};

export default LayoutHome;
