import React, { useState, useContext, useEffect } from "react";
import PopUp from "../components/PopUp";
import { Link } from "react-router-dom";
import { AccountContext } from "../context/Account";
import moment from "moment";
import ButtonLink from "../components/ButtonLink";
import ButtonClose from "../components/ButtonClose";
import fetchExampleTemplates from "../utils/fetchExampleTemplates";
import SubNav from "../components/SubNav";

export default function SurveyTemplateDashboardExample() {
    const [templates, setTemplates] = useState([]);
    const [popUp, setPopUp] = useState(null);
    const [popUpType, setPopUpType] = useState(null);

    const [u_id, set_u_id] = useState("");
    const [noSurvey, setNoSurvey] = useState(false);

    const { getSession } = useContext(AccountContext);

    const [sortConfig, setSortConfig] = useState({
        key: "id",
        direction: "descending",
    });

    // Get u_id
    useEffect(() => {
        getSession().then((session) => {
            const user = session.accessToken.payload.username;
            set_u_id(user);
        });
        var currentSize = JSON.stringify(sessionStorage).length;
    }, []);

    useEffect(() => {
        fetchExampleTemplates(u_id, setTemplates);
    }, [u_id]);

    useEffect(() => {
        // After 5 seconds, hide the error component
        const timeoutId = setTimeout(() => {
            setNoSurvey(true);
        }, 3000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    if (templates.length === 0 && !noSurvey) {
        return (
            <div className="w-full h-full items-center flex justify-center">
                Loading...
            </div>
        );
    }

    if (noSurvey && templates.length === 0) {
        return (
            <div className="h-full flex flex-col items-center justify-center">
                <div>No Templates have been created yet</div>
                <button className="bg-primary text-sm text-white p-3 mt-6 rounded-lg">
                    <Link to={`/surveyTemplateCreationForm`}>
                        Create a new Template
                    </Link>
                </button>
            </div>
        );
    }

    const handleSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    return (
        <>
            {popUp && (
                <PopUp
                    popUpType={popUpType}
                    popUp={popUp}
                    onClose={() => setPopUp(null)}
                />
            )}
            <div className="w-full px-5 xl:w-3/4">
                <SubNav />
                <table className="w-full border border-slate-200 mt-4 rounded-lg">
                    <thead>
                        <tr>
                            <th>
                                <button
                                    className="text-black border-none focus:border-none hover:border-none"
                                    onClick={() => handleSort("templateName")}
                                >
                                    Template{" "}
                                    {sortConfig.key === "templateName" ? (
                                        sortConfig.direction === "ascending" ? (
                                            "▲"
                                        ) : (
                                            "▼"
                                        )
                                    ) : (
                                        <span className="text-stone-300">
                                            ▲
                                        </span>
                                    )}
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {templates
                            .sort((a, b) => {
                                if (sortConfig.key === "id") {
                                    const dateA = moment(
                                        a.date,
                                        "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                    ).valueOf();
                                    const dateB = moment(
                                        b.date,
                                        "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                    ).valueOf();

                                    if (dateA < dateB) {
                                        return sortConfig.direction ===
                                            "ascending"
                                            ? -1
                                            : 1;
                                    }
                                    if (dateA > dateB) {
                                        return sortConfig.direction ===
                                            "ascending"
                                            ? 1
                                            : -1;
                                    }
                                } else {
                                    if (a[sortConfig.key] < b[sortConfig.key]) {
                                        return sortConfig.direction ===
                                            "ascending"
                                            ? -1
                                            : 1;
                                    }
                                    if (a[sortConfig.key] > b[sortConfig.key]) {
                                        return sortConfig.direction ===
                                            "ascending"
                                            ? 1
                                            : -1;
                                    }
                                }
                                return 0;
                            })
                            .map((survey) => (
                                <tr key={survey.id}>
                                    <td>
                                        <Link
                                            to={`/surveyTemplateExample?surveyTemplateId=${survey.id}`}
                                            className="text-primary"
                                        >
                                            {survey.templateName}
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
