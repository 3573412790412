import React, { Children } from 'react';

const Tooltip = ({ text, total_valid_responses, total_responses, total_sent, children }) => {
  return (
    <div className="relative group w-max">
        {children}
      <div className="w-max opacity-0 pointer-events-none border-2 border-solid absolute z-10 bg-white text-grey-800 text-sm rounded-lg py-2 px-4 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
        {text}
        <p>Valid Responses = {total_valid_responses}</p>
        <p>Total Responses = {total_responses}</p>
        <p>Total Recipients = {total_sent}</p>
      </div>
    </div>
  );
};

export default Tooltip;
