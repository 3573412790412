import { Routes, Route } from "react-router-dom";
import RequireAuth from "./utils/RequireAuth";
import Layout from "./components/Layout";
import LayoutHome from "./components/LayoutHome";

import Missing from "./components/Missing";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";

import Survey from "./pages/survey";
import SurveyCreationForm from "./pages/SurveyCreationForm";
import Home from "./pages/Home";
import Setting from "./pages/Setting";
import SurveyTemplateCreationForm from "./pages/SurveyTemplateCreationForm";
import SurveyTemplateDashboard from "./pages/SurveyTemplateDashboard";
import SurveyTemplate from "./pages/SurveyTemplate";

import HomeContact from "./pages/HomeContact";
import HomeFeatures from "./pages/HomeFeatures";
import HomeHow from "./pages/HomeHow";
import HomeWarehouse from "./pages/HomeWarehouse";
import HomeSwiftSurvey from "./pages/HomeSwiftSurvey";
import HomeSBMGateway from "./pages/HomeSBMGateway";
import AboutSwiftSurvey from "./pages/AboutSwiftSurvey";
import SurveyTemplateDashboardExample from "./pages/SurveyTemplateDashboardExample";
import SurveyTemplateExample from "./pages/SurveyTemplateExample";

function App() {
	return (
		<Routes>
			<Route path="/" element={<LayoutHome />}>
				<Route path="" element={<Home />} />
				<Route path="contact" element={<HomeContact />} />
				<Route path="features" element={<HomeFeatures />} />
				<Route path="how" element={<HomeHow />} />
				<Route path="warehouse" element={<HomeWarehouse />} />
				<Route path="warehouse/swiftsurvey" element={<HomeSwiftSurvey />} />
				<Route path="warehouse/sbmgateway" element={<HomeSBMGateway />} />
				
			</Route>
			<Route path="/" element={<Layout />}>
				{/* public routes */}
				<Route path="login" element={<Login />} />
				<Route path="register" element={<Register />} />

				<Route element={<RequireAuth />}>
					<Route path="setting" element={<Setting />} />
					<Route path="aboutswiftsurvey" element={<AboutSwiftSurvey />} />
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="surveyTemplateDashboard" element={<SurveyTemplateDashboard />} />
					<Route path="surveyTemplateDashboardExample" element={<SurveyTemplateDashboardExample />} />
					<Route path="surveyTemplate" element={<SurveyTemplate />} />
					<Route path="surveyTemplateExample" element={<SurveyTemplateExample />} />
					<Route path="survey" element={<Survey />} />
					<Route
						path="surveyCreationForm"
						element={<SurveyCreationForm />}
					/>
					<Route path="surveyTemplateCreationForm" element={<SurveyTemplateCreationForm />} />
				</Route>
        
				{/* catch all */}
				<Route path="*" element={<Missing />} />
			</Route>
		</Routes>
	);
}

export default App;
