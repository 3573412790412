function AppointmentModal() {
    return (
        <ol>
            <li className="list-disc ml-5">
                Trigger: Upcoming event or appointment scheduled in a calendar
                application.
            </li>
            <li className="list-disc ml-5">
                Action: Send an SMS reminder to the participant with event
                details, time, and location.
            </li>
        </ol>
    );
}

export default AppointmentModal;
