import React, { useState } from "react";
import PopUp from "../PopUp";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import Papa from "papaparse";
import { FaUpload } from "react-icons/fa";

import PhoneMock from "../PhoneMock";
import ButtonClose from "../ButtonClose";
import ButtonOnClick from "../ButtonOnClick";
import ButtonFullWidth from "../ButtonFullWidth";
import Stepper from "./Stepper";

import Modal from "../Modal";

//import { CognitoIdentityServiceProvider } from "aws-sdk";

export default function S1SurveyCreationForm({
    step,
    setStep,
    s1Form,
    sets1Form,
}) {
    const [overHundred, setoverHundred] = useState(false);
    const [popUp, setPopUp] = useState(null);
    const [popUpType, setPopUpType] = useState(null);
    const [isProcessed, setIsProcessed] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    const handleNextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const handlePrevStep = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const handleRemoveCsv = (setFieldValue) => {
        setFieldValue("file", "");
        setFieldValue("fileName", "");
        setFieldValue("personalisation", []);
        setFieldValue("contacts", []);
    };

    const handleFileUpload = (event, setFieldValue) => {
        const file = event.target.files[0];
        const fileName = file.name;
        setFieldValue("fileName", fileName);

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const data = results.data;

                if (data.length === 0) {
                    console.log("No data found in the file.");
                    return;
                }

                // Get Contacts as an array
                let contacts = data.slice(0, 100).map((row) => row["Contact"]);
                setFieldValue("contacts", contacts);

                if (!contacts) {
                    // TODO add error
                    console.log("No Contact column was found");
                } else {
                    // Key the rest of the personalisation to contacts
                    const headers = Object.keys(data[0]).filter(
                        (header) => header !== "Contact"
                    );
                    const personalisationDict = {};
                    data.forEach((row) => {
                        const contact = row["Contact"];
                        const rowData = {};

                        headers.forEach((header) => {
                            rowData[header] = row[header];
                        });

                        personalisationDict[contact] = rowData;
                    });

                    setFieldValue("personalisation", [
                        headers,
                        personalisationDict,
                    ]);
                }

                // Change the state if there are more than 100 rows
                if (data.length > 100) {
                    setoverHundred(true);
                    setIsOpened(true);
                }
            },
            error: (err) => {
                console.error(err);
            },
        });
    };

    return (
        <>
            <Modal
                isProcessed={isProcessed}
                setIsProcessed={setIsProcessed}
                isOpened={isOpened}
                setIsOpened={setIsOpened}
            />
            {popUp && (
                <PopUp
                    popUpType={popUpType}
                    popUp={popUp}
                    onClose={() => setPopUp(null)}
                />
            )}
            <Formik
                initialValues={{
                    newContact: "",
                    contacts: s1Form.contacts,
                    personalisation: s1Form.personalisation,
                    fileName: s1Form.fileName,
                }}
                validationSchema={Yup.object({
                    contacts: Yup.array()
                        .min(1, "Add atleast one contact")
                        .of(Yup.string().required("Contacts cannot be empty"))
                        .required("Required"),
                })}
                onSubmit={(values) => {
                    const contacts = values.contacts;
                    const personalisation = values.personalisation;
                    const fileName = values.fileName;

                    const formData = {
                        contacts: contacts,
                        personalisation: personalisation,
                        fileName: fileName,
                    };

                    sets1Form({ contacts, personalisation, fileName });
                    handleNextStep();
                }}
            >
                {({ errors, touched, values, setFieldValue, handleBlur }) => (
                    <Form className="flex flex-row gap-x-9 h-full xl:w-3/4">
                        <div className="flex flex-col p-5 rounded bg-white drop-shadow-md w-full">
                            <Stepper step={step} />
                            <div className="flex gap-5 mt-5 w-full flex-col xl:flex-row">
                                {/* Upload CSV field */}
                                <div className="w-full xl:w-1/2">
                                    <label htmlFor="file">
                                        Upload Contacts CSV File
                                    </label>
                                    <div
                                        className="flex space-x-2 items-center border-2 border-gray-500 p-2 my-2 rounded-md w-full 
										focus-within:border-primary"
                                    >
                                        <FaUpload className="my-1" />
                                        <input
                                            type="file"
                                            name="file"
                                            accept=".csv"
                                            className="text-xs focus:outline-none"
                                            onChange={(event) =>
                                                handleFileUpload(
                                                    event,
                                                    setFieldValue,
                                                    values
                                                )
                                            }
                                            onBlur={handleBlur}
                                            autoFocus
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="file">
                                            Personalisation:
                                        </label>
                                        {values.personalisation.length > 0 ? (
                                            <div className="items-center border-2 border-gray-500 p-2 my-2 rounded-md w-full">
                                                <h3 className="text-base">
                                                    {
                                                        values
                                                            .personalisation[0]
                                                            .length
                                                    }{" "}
                                                    additional headers were
                                                    found:
                                                </h3>

                                                <ul className="list-disc">
                                                    {values.personalisation[0].map(
                                                        (header, index) => (
                                                            <li
                                                                className="ml-3"
                                                                key={index}
                                                            >
                                                                {header}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                                <ButtonFullWidth
                                                    type="button"
                                                    message="Remove CSV File"
                                                    action={() =>
                                                        handleRemoveCsv(
                                                            setFieldValue
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div className="flex space-x-2 items-center border-2 border-gray-500 p-2 my-2 rounded-md w-full">
                                                <p className="text-xs">
                                                    None were found in the CSV
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* contact */}
                                <div className="w-full xl:w-1/2">
                                    <FieldArray name="contacts">
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {!Array.isArray(
                                                    errors.contacts
                                                ) && touched.contacts ? (
                                                    <div className="text-red-400">
                                                        {errors.contacts}
                                                    </div>
                                                ) : touched.contacts ? (
                                                    <div className="text-red-400">
                                                        {errors.contacts.map(
                                                            (error, index) => (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    {error}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <label
                                                        htmlFor="contacts"
                                                        className=""
                                                    >
                                                        Contacts:
                                                    </label>
                                                )}
                                                <div
                                                    className="flex border-2 border-gray-500 p-2 mt-2 rounded-md w-full
													focus-within:border-primary"
                                                >
                                                    <Field
                                                        name="newContact"
                                                        placeholder="Add new recipient"
                                                        className="focus:outline-none w-11 grow"
                                                        type="text"
                                                        onKeyDown={(event) => {
                                                            if (
                                                                event.key ===
                                                                "Enter"
                                                            ) {
                                                                event.preventDefault();
                                                                const newOption =
                                                                    event.target.value.trim();
                                                                const contacts =
                                                                    values.contacts;
                                                                if (
                                                                    contacts.length >=
                                                                    100
                                                                ) {
                                                                    return;
                                                                }
                                                                if (newOption) {
                                                                    push(
                                                                        newOption
                                                                    );
                                                                    setFieldValue(
                                                                        "newContact",
                                                                        ""
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                        onPaste={(event) => {
                                                            const pastedData =
                                                                event.clipboardData.getData(
                                                                    "text"
                                                                );
                                                            const parsed_data =
                                                                pastedData
                                                                    .split("\n")
                                                                    .map(
                                                                        (
                                                                            value
                                                                        ) =>
                                                                            value.trim()
                                                                    )
                                                                    .filter(
                                                                        (
                                                                            value
                                                                        ) =>
                                                                            value !==
                                                                            ""
                                                                    );

                                                            const remainingSpace =
                                                                100 -
                                                                values.contacts
                                                                    .length;
                                                            const newContacts =
                                                                parsed_data.slice(
                                                                    0,
                                                                    remainingSpace
                                                                );

                                                            setFieldValue(
                                                                "contacts",
                                                                values.contacts.concat(
                                                                    newContacts
                                                                )
                                                            );
                                                            setTimeout(() => {
                                                                setFieldValue(
                                                                    "newContact",
                                                                    ""
                                                                );
                                                            }, 0);
                                                        }}
                                                    />
                                                    <ButtonOnClick
                                                        message="Add Recipient"
                                                        action={() => {
                                                            const newContact =
                                                                values.newContact.trim();
                                                            if (newContact) {
                                                                push(
                                                                    newContact
                                                                );
                                                                setFieldValue(
                                                                    "newContact",
                                                                    ""
                                                                );
                                                            }
                                                        }}
                                                        disabled={
                                                            !values.newContact
                                                        }
                                                    />
                                                </div>
                                                <div className="overflow-y-auto scrollbar-hidden h-64">
                                                    {values.contacts.length >
                                                        0 &&
                                                        values.contacts.map(
                                                            (
                                                                contact,
                                                                index
                                                            ) => (
                                                                <div
                                                                    className="flex border-2 border-gray-500 p-2 mt-2 rounded-md w-full"
                                                                    key={index}
                                                                >
                                                                    <Field
                                                                        name={`contacts.${index}`}
                                                                        placeholder="Contact cannot be empty"
                                                                        type="text"
                                                                        className="grow"
                                                                    />
                                                                    <div>
                                                                        <ButtonClose
                                                                            remove={() =>
                                                                                remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>
                            <div className="grow flex flex-col 2xl:flex-row gap-x-5 w-full"></div>
                            <div>
                                <div className="flex gap-x-5 mx-12">
                                    <ButtonFullWidth
                                        type="button"
                                        message="Previous"
                                        action={handlePrevStep}
                                        className={`${
                                            step === 1
                                                ? "bg-gray-300 text-gray-500 border-gray-300"
                                                : null
                                        }`}
                                        applyClassName={step === 1}
                                        disabled={step === 1}
                                    />
                                    <ButtonFullWidth
                                        type="submit"
                                        message="Next"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sticky top-5 h-fit">
                            <PhoneMock
                                message={values.message}
                                surveyType={values.surveyType}
                                options={values.options}
                                ratingsArray={values.ratingsArray}
                                noYesArray={values.noYesArray}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
