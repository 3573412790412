import React from "react";

export default function ButtonOnClick({ message, action, disabled }) {
	return (
	  <button
		type="button"
		className={`border-2 text-sm  px-2 mx-2 rounded-lg whitespace-nowrap flex-none ${
		  disabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
		  			: 'bg-transparent border-primary text-primary' + ' ' +  
		  				'hover:bg-primary hover:text-white hover:border-primary' + ' ' +
						'focus:bg-primary focus:text-white focus:border-primary'
		}`}
		onClick={action}
		disabled={disabled}
	  >
		{message}
	  </button>
	);
  }
