import { useRef, useState, useEffect } from "react";
import {
    faCheck,
    faTimes,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import UserPool from "../../context/UserPool";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

import ButtonFullWidth from "../ButtonFullWidth";
// import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk";
import PopUp from "../PopUp";

const USER_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[0-9]).{6,52}$/;
const REGISTER_URL = "/register";

const S3Register = ({ step, handlePrevStep, handleNextStep, s1Form }) => {
    const navigate = useNavigate();
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState("");
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState("");
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState("");
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [popUp, setPopUp] = useState(null);
    const [popUpType, setPopUpType] = useState(null);

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user]);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd]);

    const handleSubmit = async (e) => {
		e.preventDefault();

		const attributeList = [];

		attributeList.push(
			new CognitoUserAttribute({
				Name: "custom:api_key",
				Value: s1Form.apiKey,
			})
		);

		attributeList.push(
			new CognitoUserAttribute({
				Name: "custom:api_secret",
				Value: s1Form.apiSecret,
			})
		);

		attributeList.push(
			new CognitoUserAttribute({
				Name: "custom:vendor",
				Value: s1Form.vendor,
			})
		);

		UserPool.signUp(user, pwd, attributeList, null, (err, data) => {
			if (err) {
				if (err.code === "UsernameExistsException") {
					setPopUpType("error");
					setPopUp(
						"An account with the given email already exists. Please try again with a different email address."
					);
				} else {
					setPopUpType("error");
					setPopUp(err.toString());
					console.log(err);
				}
			} else {

				AWS.config.update({
					region: "ap-southeast-2",
					credentials: {
						accessKeyId: "AKIAUR6PPTBIAZKINENB",
						secretAccessKey:
							"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",
					},
				});

				const cognito = new AWS.CognitoIdentityServiceProvider();

				const params = {
					UserPoolId: "ap-southeast-2_2r64XeNdz",
					Username: user,
				};

				cognito.adminConfirmSignUp(params, function (err, data) {
					if (err) {
						console.log(err, err.stack);
					} else {
						console.log(data);
					}
				});

				// clear state and controlled inputs
				setUser("");
				setPwd("");
				setMatchPwd("");

				navigate("/login", {
					state: {
						popUpType: "success",
						popUp: "Registration successful! Please log in.",
					},
				});
			}
		});
	};

    return (
        <>
            {popUp && (
                <PopUp
                    popUpType={popUpType}
                    popUp={popUp}
                    onClose={() => setPopUp(null)}
                />
            )}
            <form onSubmit={handleSubmit}>
                <div className="flex-1">
                    <div className="mt-6">
                        {/* username */}
                        <div className="pb-4 relative">
                            <label htmlFor="username">
                                Email:
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className={
                                        validName
                                            ? "text-green-600 ml-1"
                                            : "hide"
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={
                                        validName || !user
                                            ? "hide"
                                            : "text-red-600 ml-1"
                                    }
                                />
                            </label>
                            <input
                                className="border-2 border-gray-500 p-2 rounded-md w-full 
									focus:outline-none focus:border-primary"
                                type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                                aria-text-red-600
                                ml-1={validName ? "false" : "true"}
                                aria-describedby="uidnote"
                                onFocus={() => setUserFocus(true)}
                                onBlur={() => setUserFocus(false)}
                            />
                            <p
                                id="uidnote"
                                className={
                                    userFocus && user && !validName
                                        ? "text-xs rounded-md bg-gray-400 text-white px-1 py-1 mt-1 bottom-20 absolute"
                                        : "offscreen"
                                }
                            >
                                <FontAwesomeIcon icon={faInfoCircle} />
                                Valid email required
                            </p>
                        </div>
                        {/* Password */}
                        <div className="pb-4 relative">
                        <p
                                id="pwdnote"
                                className={
                                    pwdFocus && !validPwd
                                        ? "text-xs rounded-md bg-gray-400 text-white px-1 py-1 -mt-14 absolute"
                                        : "offscreen"
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="mr-1"
                                />
                                6 to 60 characters.
                                <br />
                                Must include a lower case letter and a number.
                                <br />
                                Allowed special characters:{" "}
                                <span aria-label="exclamation mark">
                                    !
                                </span>{" "}
                                <span aria-label="at symbol">@</span>{" "}
                                <span aria-label="hashtag">#</span>{" "}
                                <span aria-label="dollar sign">$</span>{" "}
                                <span aria-label="percent">%</span>
                            </p>
                            <label htmlFor="password">
                                Password:
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className={
                                        validPwd
                                            ? "text-green-600 ml-1"
                                            : "hide"
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={
                                        validPwd || !pwd
                                            ? "hide"
                                            : "text-red-600 ml-1"
                                    }
                                />
                            </label>
                            <input
                                className="border-2 border-gray-500 p-2 rounded-md w-full
									focus:outline-none focus:border-primary"
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                                aria-text-red-600
                                ml-1={validPwd ? "false" : "true"}
                                aria-describedby="pwdnote"
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                            />
                        </div>
                        {/* Confirm Password */}
                        <div className="pb-4">
                            
                        <p
                                id="apikeynote"
                                className={
                                    matchFocus && !validMatch
                                        ? "text-xs rounded-md bg-gray-400 text-white px-1 py-1 -mt-6 absolute"
                                        : "offscreen"
                                }
                            >
                                <FontAwesomeIcon icon={faInfoCircle} />
                                Must match the first password input field.
                            </p>
                            <label htmlFor="confirm_pwd">
                                Confirm Password:
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className={
                                        validMatch && matchPwd
                                            ? "text-green-600 ml-1"
                                            : "hide"
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={
                                        validMatch || !matchPwd
                                            ? "hide"
                                            : "text-red-600 ml-1"
                                    }
                                />
                            </label>
                            
                            <input
                                className="border-2 border-gray-500 p-2 rounded-md w-full 
									focus:outline-none focus:border-primary"
                                type="password"
                                id="confirm_pwd"
                                onChange={(e) => setMatchPwd(e.target.value)}
                                value={matchPwd}
                                required
                                aria-text-red-600
                                ml-1={validMatch ? "false" : "true"}
                                aria-describedby="confirmnote"
                                onFocus={() => setMatchFocus(true)}
                                onBlur={() => setMatchFocus(false)}
                            />
                        </div>

                        <div className="flex gap-x-5 mx-auto lg:mx-12">
                            <ButtonFullWidth
                                type="button"
                                message="Previous"
                                action={handlePrevStep}
                            />
                            <ButtonFullWidth 
                                type="submit" 
                                message="Register" 
                                className="border-gray-400 bg-gray-400 text-white"
                                applyClassName={!validName || !validPwd || !validMatch}
                                disabled={!validName || !validPwd || !validMatch}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}; 


export default S3Register;
