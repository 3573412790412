import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import ResizeObserver from "resize-observer-polyfill";

const useResizeObserver = (ref) => {
	const [dimensions, setDimensions] = useState(null);
	useEffect(() => {
		const observeTarget = ref.current;
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setDimensions(entry.contentRect);
			});
		});
		resizeObserver.observe(observeTarget);
		return () => {
			resizeObserver.unobserve(observeTarget);
		};
	}, [ref]);
	return dimensions;
};

function Graph({ arrayOptions, arrayCounts }) {
	const svgRef = useRef();
	const wrapperRef = useRef();
	const dimensions = useResizeObserver(wrapperRef);

	// will be called initially and on every data change
	useEffect(() => {
		const svg = d3.select(svgRef.current).style("overflow", "visible");

		if (!dimensions) return;
		//Setting up svg container
		const fontSize = 16;
		const tickSize = 5;
		const tickPadding = 10;
		const shiftValue = fontSize + tickSize + tickPadding;

		// calculate container width and height
		const containerWidth = svgRef.current.parentElement.offsetWidth;
		const containerHeight =
			svgRef.current.parentElement.offsetHeight - shiftValue;

		// setting up the scaling
		const xScale = d3
			.scaleBand()
			.domain(arrayOptions.map((value, index) => value))
			.range([0, dimensions.width])
			.padding(0.5);

		const yScale = d3
			.scaleLinear()
			.domain([0, d3.max(arrayCounts) + 5])
			.range([dimensions.height, 0]);

		// setting the axes
		const xAxis = d3.axisBottom(xScale).ticks(arrayCounts.length);
		svg.select(".x-axis")
			.style("transform", `translateY(${dimensions.height}px)`)
			.call(xAxis);
		const yAxis = d3
			.axisLeft(yScale)
			.ticks(5)
			.tickFormat(d3.format("d"))
			.tickSize(tickSize)
			.tickPadding(tickPadding);
		svg.select(".y-axis").call(yAxis);

		// setting the svg data
		svg.selectAll(".bar")
			.data(arrayCounts)
			.join("rect")
			.attr("class", "bar")
			.style("transform", "scale(1, -1)")
			.attr("x", (value, i) => xScale(arrayOptions[i]))
			.attr("y", -dimensions.height)
			.attr("width", xScale.bandwidth())
			// .on("mouseenter", function (event, value) {
			// 	// events have changed in d3 v6:
			// 	// https://observablehq.com/@d3/d3v6-migration-guide#events
			// 	const index = svg.selectAll(".bar").nodes().indexOf(this);
			// 	svg.selectAll(".tooltip")
			// 		.data([value])
			// 		.join((enter) =>
			// 			enter.append("text").attr("y", yScale(value) - 4)
			// 		)
			// 		.attr("class", "tooltip")
			// 		.text(value)
			// 		.attr("x", xScale(index) + xScale.bandwidth() / 2)
			// 		.attr("text-anchor", "middle")
			// 		.transition()
			// 		.attr("y", yScale(value) - 8)
			// 		.attr("opacity", 1);
			// })
			// .on("mouseleave", () => svg.select(".tooltip").remove())
			.transition()
			.duration(500)
			.attr("fill", "#00778B")
			.attr("height", (value) => dimensions.height - yScale(value));
	}, [arrayCounts, arrayOptions, dimensions]);

	return (
		<div
			className="h-full w-full"
			ref={wrapperRef}
			style={{ marginBottom: "2rem" }}
		>
			<svg className="w-full" ref={svgRef}>
				<g className="x-axis text-sm" />
				<g className="y-axis text-sm" />
			</svg>
		</div>
	);
}

export default Graph;
